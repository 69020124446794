import {permissionService} from "@/util/permission-service";


const EmailMenuItems = {
  roles: [
    permissionService.CALL_CENTER_MANAGER_ROLE,
  ],
  link: {
    name: 'sidebar.emails',
    icon: 'filter'
  },
  children: [
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.call_center_emails',
        route: 'leads.call_center_emails'
      }
    },
    {
      roles: [
        permissionService.ADMIN_ROLE
      ],
      link: {
        name: 'sidebar.email_scheduled',
        route: 'email-scheduled.index'
      }
    },
    {
      roles: [
        permissionService.ADMIN_ROLE
      ],
      link: {
        name: 'sidebar.email_archive',
        route: 'email-archive.index'
      }
    },
  ]
};


export default EmailMenuItems;
