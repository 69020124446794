import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const DidacticsRoutes =
  {
    path: '/didactics',
    name: 'didactics',
    component: DashboardLayout,
    redirect: '/classrooms',
    children: [
      {
        path: 'dashboard',
        name: 'didactics.dashboard',
        components: {default: () => import('@/pages/Didactics/DashboardView')},
        meta: {
          requiresAuth: true,
          title: 'dashboard',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'lessons',
        name: 'didactics.lessons.index',
        components: {default: () => import('@/pages/Didactics/IndexLessonPage')},
        meta: {
          requiresAuth: true,
          title: 'lessons',
          roles: [permissionService.TUTOR_ROLE]
        },
      },
      {
        path: 'classrooms',
        name: 'didactics.classrooms.index',
        components: {default: () => import('@/pages/Didactics/IndexClassroomArchivePage')},
        meta: {
          requiresAuth: true,
          title: 'classrooms',
          roles: [permissionService.TUTOR_ROLE]
        },
      },
      {
        path: 'classrooms/pending',
        name: 'didactics.classrooms.pending.index',
        components: {default: () => import('@/pages/Didactics/IndexClassroomPendingPage')},
        meta: {
          requiresAuth: true,
          title: 'classrooms_pending',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'classrooms/pending/:id',
        name: 'didactics.classrooms.pending.show',
        components: {default: () => import('@/pages/Didactics/ShowClassroomPendingPage')},
        meta: {
          requiresAuth: true,
          title: 'classrooms_pending',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'classrooms/on-going/:id',
        name: 'didactics.classrooms.on-going.show',
        components: {default: () => import('@/pages/Didactics/ShowClassroomOnGoingPage')},
        meta: {
          requiresAuth: true,
          title: 'classroom_on_going',
          roles: [permissionService.TUTOR_ROLE]
        },
      },
      {
        path: 'classrooms/archive/certificate/:id',
        name: 'didactics.classrooms.archive.certificate.show',
        components: {default: () => import('@/pages/Didactics/ShowClassroomArchiveCertificatePage')},
        meta: {
          requiresAuth: true,
          title: 'classroom_archive',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      }
    ]
  };

export default DidacticsRoutes;
