import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const CallCenterRoutes =
  {
    path: '/call-center',
    name: 'call-center',
    component: DashboardLayout,
    redirect: '/timesheet',
    children: [
      {
        path: 'timesheet',
        name: 'call-center.timesheet',
        components: {default: () => import('@/pages/CallCenter/TimesheetPage')},
        meta: {
          requiresAuth: true,
          title: 'timesheet',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'timesheet/daily',
        name: 'call-center.timesheet.daily',
        components: {default: () => import('@/pages/CallCenter/TimesheetDailyWizardPage')},
        meta: {
          requiresAuth: true,
          title: 'timesheet_daily',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'timesheet/operator-yearly/:id/:year',
        name: 'call-center.timesheet.operator.yearly',
        components: {default: () => import('@/pages/CallCenter/TimesheetOperatorYearlyPage')},
        meta: {
          requiresAuth: true,
          title: 'timesheet_operator',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'timesheet/operator-monthly/:id/:year/:month',
        name: 'call-center.timesheet.operator.monthly',
        components: {default: () => import('@/pages/CallCenter/TimesheetOperatorMonthlyPage')},
        meta: {
          requiresAuth: true,
          title: 'timesheet_operator',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'operator',
        name: 'operator.handle_lead',
        components: {default: () => import('@/pages/Operator/HandleLeadPage')},
        meta: {
          requiresAuth: true,
          title: 'call_center_operator',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE]
        },
      },
      {
        path: 'operator/search-registry',
        name: 'call-center.operator.search-registry',
        components: {default: () => import('@/pages/CallCenter/SearchRegistryPage')},
        meta: {
          requiresAuth: true,
          title: 'call_center_operator_search_registry',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE]
        },
      },

    ]
  };

export default CallCenterRoutes;
