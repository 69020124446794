import DashboardLayout from '@/pages/Layout/DashboardLayout.vue';
import {permissionService} from "@/util/permission-service";

const CourseRoutes =
  {
    path: '/courses',
    name: 'courses',
    component: DashboardLayout,
    redirect: '/courses',
    children: [
      {
        path: '',
        name: 'courses.index',
        components: {default: () => import('@/pages/Courses/IndexCoursePage')},
        meta: {
          requiresAuth: true,
          title: 'courses',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'wizard/certificate',
        name: 'courses.certificates.wizard',
        components: {default: () => import('@/pages/Courses/CertificateWizardPage')},
        meta: {
          requiresAuth: true,
          title: 'certificates',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'wizard/:type',
        name: 'courses.wizard',
        components: {default: () => import('@/pages/Courses/CourseWizardPage')},
        meta: {
          requiresAuth: true,
          title: 'courses',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'certificates',
        name: 'courses.certificates.index',
        components: {default: () => import('@/pages/Courses/IndexCertificatePage')},
        meta: {
          requiresAuth: true,
          title: 'certificates',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'certificates/:id',
        name: 'courses.certificates.show',
        components: {default: () => import('@/pages/Courses/ShowCertificatePage')},
        meta: {
          requiresAuth: true,
          title: 'certificate',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'workshops',
        name: 'courses.workshops.index',
        components: {default: () => import('@/pages/Courses/IndexWorkshopPage')},
        meta: {
          requiresAuth: true,
          title: 'workshops',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'workshops/:id',
        name: 'courses.workshops.show',
        components: {default: () => import('@/pages/Courses/ShowWorkshopPage')},
        meta: {
          requiresAuth: true,
          title: 'workshop',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: ':id',
        name: 'courses.show',
        components: {default: () => import('@/pages/Courses/ShowCoursePage')},
        meta: {
          requiresAuth: true,
          title: 'course',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
    ]
  };

export default CourseRoutes;
