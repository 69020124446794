import CallCenterMenuItems from "@/routes/partials/call-center-menu-items";
import RegistryMenuItems from "@/routes/partials/registries-menu-items";
import LeadMenuItems from "@/routes/partials/leads-menu-items";
import DealMenuItems from "@/routes/partials/deals-menu-items";
import UserMenuItems from "@/routes/partials/user-menu-items";
import ReportsMenuItems from "@/routes/partials/reports-menu-items";
import StudentMenuItems from "./partials/students-menu-items";
import SettingsMenuItems from "@/routes/partials/settings-menu-items";
import DidacticsMenuItems from "@/routes/partials/didactics-menu-items";
import CourseMenuItems from "@/routes/partials/course-menu-items";
import BalancesMenuItems from "@/routes/partials/balances-menu-items";
import EmailMenuItems from "@/routes/partials/emails-menu-items";
import PostSellingMenuItems from "@/routes/partials/post-selling-menu-items";

const menuItems = [
  UserMenuItems,
  EmailMenuItems,
  RegistryMenuItems,
  LeadMenuItems,
  DealMenuItems,
  PostSellingMenuItems,
  CallCenterMenuItems,
  StudentMenuItems,
  DidacticsMenuItems,
  CourseMenuItems,
  ReportsMenuItems,
  BalancesMenuItems,
  SettingsMenuItems
];

export default menuItems;
