const ClassroomPending = {
  id: null,
  code: null,
  status: null,
  type: null,
  schedulation: null,
  expected_start_date: null,
  certification_body: null,
  courses: [],
  lessons: [],
  students: [],
  availableStudents: [],
  tutors: [],
  availableTutors: [],
  locations: [],

};

export default ClassroomPending
