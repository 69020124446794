import _ from "lodash";
import Campaign from "@/models/campaign";
import CallCenterLead from "@/models/callCenterLead";
import Registry from "@/models/registry";
import Provider from "@/models/provider";
import Lead from "@/models/lead";
import DealDate from "@/models/dealDate";
import Vue from "vue";

export const mutations = {

  setCampaign(state, data) {
    state.campaign = data;
  },

  setProvider(state, data) {
    state.provider = data;
  },

  setRegistry(state, data) {
    state.registry = data;
  },

  setAddresses(state, data) {
    state.registry.addresses = data;
  },

  setEmails(state, data) {
    state.registry.emails = data;
  },

  setPhones(state, data) {
    state.registry.phones = data;
  },

  setLead(state, data) {
    state.lead = data;
  },

  setSales(state, data) {
    state.sales = data;
  },

  setCourses(state, data) {
    state.courses = data;
  },

  setLeadEventLogs(state, data) {
    state.leadEventLogs = data;
  },

  setStatusTo(state, data) {
    state.status_to = data;
  },

  setSubStatusTo(state, data) {
    state.sub_status_to = data;
  },

  setDeadline(state, data) {
    state.deadline = data;
  },

  setCallCenterLead(state, data) {
    state.callCenterLead = data;
  },

  setSaleAppointment(state, data) {
    Vue.set(state, 'saleAppointment', data);
  },

  setSkipIntro(state, data) {
    state.skipIntro = data;
  },

  resetState(state) {
    state.campaign = _.cloneDeep(Campaign);
    state.callCenterLead = _.cloneDeep(CallCenterLead);
    state.registry = _.cloneDeep(Registry);
    state.provider = _.cloneDeep(Provider);
    state.lead = _.cloneDeep(Lead);
    state.courses = [];
    state.leadEventLogs = [];
    state.sales = [];
    state.status_to = null;
    state.sub_status_to = null;
    state.deadline = null;
    state.saleAppointment = _.cloneDeep(DealDate);
    state.skipIntro =  false;

  }
}
