var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlideYUpTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"modal",staticClass:"modal fade",class:[
      { 'show d-block': _vm.show },
      { 'd-none': !_vm.show },
      { 'modal-mini': _vm.type === 'mini' }
    ],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"modal-dialog",class:[
        { 'modal-notice': _vm.type === 'notice' },
        { 'modal-dialog-centered': _vm.centered },
        _vm.modalClasses
      ],attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content",class:[
          _vm.gradient ? ("bg-gradient-" + _vm.gradient) : '',
          _vm.modalContentClasses
        ]},[_c('card',{staticClass:"p-0 m-0",attrs:{"body-classes":"p-0"}},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header",class:[_vm.headerClasses]},[_c('base-alert',{staticClass:"text-uppercase flex-grow-1 text-white\n            mb-0 d-flex justify-content-between align-items-center py-2"},[_vm._t("header"),_vm._t("close-button",function(){return [(_vm.showClose)?_c('base-button',{attrs:{"size":"sm","link":"","data-dismiss":"modal","aria-label":"Close","icon":""},on:{"click":_vm.closeModal}},[_c('octo-icon',{staticClass:"text-white",attrs:{"icon":"wrong"}})],1):_vm._e()]})],2)],1):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_vm._t("default")],2):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer p-2",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }