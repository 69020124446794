import {permissionService} from "@/util/permission-service";

const UserMenuItems = {
  roles: [
    permissionService.ADMIN_ROLE,
    permissionService.CALL_CENTER_MANAGER_ROLE,
    permissionService.SALES_MANAGER_ROLE,
    permissionService.TEACHING_MANAGER_ROLE,
  ],
  link: {
    name: 'sidebar.users',
    icon: 'crown',
  },
  children: [
    {
      roles: [
        permissionService.ADMIN_ROLE
      ],
      link: {
        name: 'sidebar.new',
        route: 'users.wizard'
      }
    },
    {
      roles: [
        permissionService.TEACHING_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.users_list',
        route: 'users.index'
      }
    },
    {
      roles: [
        permissionService.ADMIN_ROLE,
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.call_center_operator_list',
        route: 'users.operators.index'
      }
    },
    {
      roles: [
        permissionService.SALES_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.sales_list',
        route: 'users.sales.index'
      }
    },
    {
      roles: [
        permissionService.TEACHING_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.tutor_list',
        route: 'users.tutors.index'
      }
    },
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.call_center_timesheet',
        route: 'call-center.timesheet'
      }
    },
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.call_center_timesheet_daily',
        route: 'call-center.timesheet.daily'
      }
    },
  ]
};

export default UserMenuItems;
