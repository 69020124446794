<template>
  <card class="card-stats" footer-classes="mt-0" body-classes="pb-0" :show-footer-line="true">
    <div class="d-flex justify-content-between">
      <div class="mx-2" v-if="$slots.icon || icon">
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon"> <octo-icon class="icon-adapter" :icon="icon"></octo-icon> </slot>
        </div>
      </div>
      <div class="mx-2" v-if="$slots.content || title || subTitle">
        <div class="numbers">
          <slot>
            <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
            <h3 v-if="title" class="card-title">{{ title }}</h3>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';
import OctoIcon from "@/components/octo-icon/OctoIcon";

export default {
  name: 'stats-card',
  components: {
    OctoIcon,
    Card
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitle: String
  }
};
</script>
<style lang="scss" scoped>
.icon-adapter {
  line-height: 2.3em;
}

</style>
