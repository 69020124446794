import Registry from "@/models/registry";

const StudentArchive = {
  id : null,
  course_id : null,
  status : null,
  date_in : null,
  date_out : null,
  first_contact : null,
  second_contact : null,
  accounting_approval : false,
  registry: _.cloneDeep(Registry)
};

export default StudentArchive
