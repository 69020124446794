import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const BalancesRoutes =
  {
    path: '/balances',
    name: 'balances',
    component: DashboardLayout,
    redirect: '/balances',
    children: [
      {
        path: 'preview',
        name: 'balances.preview',
        components: {default: () => import('@/pages/Balances/PreviewPage')},
        meta: {
          requiresAuth: true,
          title: 'balances_preview',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: 'commissions',
        name: 'balances.commissions',
        components: {default: () => import('@/pages/Balances/IndexCommissionsPage')},
        meta: {
          requiresAuth: true,
          title: 'balances_commissions',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
    ]
  };

export default BalancesRoutes;
