const User = {
  id: null,
  username: '',
  call_link: '',
  firstname: '',
  lastname: '',
  theme_color: '',
  theme_mode: '',
  sidebar_status: '',
  operator_number: '',
  salary: 0,
  active: true,
  temp_password: true,
  roles: [],
  phones: [],
  emails: [],
  addresses: [],
  campaigns: [] ,
  defaultEmail: '',
  defaultPhone: '',
  defaultAddress: '',
  callCenterLeads: [],
};

export default User
