import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const StudentRoutes =
  {
    path: '/students',
    name: 'students',
    component: DashboardLayout,
    redirect: '/students/archive',
    children: [
      {
        path: 'wizard',
        name: 'students.wizard',
        components: {default: () => import('@/pages/Students/WizardPage')},
        meta: {
          requiresAuth: true,
          title: 'student_new',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'archive',
        name: 'students.archive.index',
        components: {default: () => import('@/pages/Students/IndexStudentArchivePage')},
        meta: {
          requiresAuth: true,
          title: 'students_archive',
          roles: [permissionService.TUTOR_ROLE]
        },
      },
      {
        path: 'archive/:id',
        name: 'students.archive.show',
        components: {default: () => import('@/pages/Students/ShowStudentArchivePage')},
        meta: {
          requiresAuth: true,
          title: 'student',
          roles: [permissionService.TUTOR_ROLE]
        },
      },
      {
        path: 'waiting',
        name: 'students.waiting.index',
        components: {default: () => import('@/pages/Students/IndexWaitingStudentsPage')},
        meta: {
          requiresAuth: true,
          title: 'students_waiting',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: ':id/waiting',
        name: 'students.waiting.show',
        components: {default: () => import('@/pages/Students/ShowStudentWaitingPage')},
        meta: {
          requiresAuth: true,
          title: 'student_waiting',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'deleted',
        name: 'students.deleted.index',
        components: {default: () => import('@/pages/Students/IndexDeletedStudentsPage')},
        meta: {
          requiresAuth: true,
          title: 'students_deleted',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
    ]
  };

export default StudentRoutes;
