import Lead from "@/models/lead";

const CallCenterLead = {
  id : null,
  status: '',
  sub_status: '',
  deadline: '',
  date: '',
  lead: _.cloneDeep(Lead)
};

export default CallCenterLead
