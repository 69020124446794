import {permissionService} from "@/util/permission-service";

const DealMenuItems =
    {
      roles: [
        permissionService.SALES_MANAGER_ROLE,
        permissionService.SALES_ROLE,
        permissionService.CALL_CENTER_OPERATOR_ROLE,
        permissionService.ACCOUNTANT_ROLE,
        permissionService.TEACHING_MANAGER_ROLE,
      ],
      link: {
        name: 'sidebar.deals',
        icon: 'euro',
        route: 'deals.index'
      },
      children: [
        {
          roles: [
            permissionService.SALES_MANAGER_ROLE
          ],
          link: {
            name: 'sidebar.dashboard',
            route: 'deals.dashboard'
          }
        },
        {
          roles: [
            permissionService.CALL_CENTER_OPERATOR_ROLE
          ],
          link: {
            name: 'sidebar.diary',
            route: 'deals.sales-diary'
          }
        },
        {
          roles: [
            permissionService.SALES_MANAGER_ROLE,  permissionService.SALES_ROLE
          ],
          link: {
            name: 'sidebar.new',
            route: 'deals.wizard'
          }
        },
        {
          roles: [
            permissionService.CALL_CENTER_OPERATOR_ROLE
          ],
          link: {
            name: 'sidebar.to_confirm',
            route: 'deals.to_confirm'
          }
        },
        {
          roles: [
            permissionService.CALL_CENTER_OPERATOR_ROLE
          ],
          link: {
            name: 'sidebar.to_recall',
            route: 'deals.to_recall'
          }
        },
        {
          roles: [
            permissionService.SALES_ROLE
          ],
          link: {
            name: 'sidebar.in_progress',
            route: 'deals.in_progress'
          }
        },
        {
          roles: [
            permissionService.CALL_CENTER_OPERATOR_ROLE,
            permissionService.SALES_ROLE,
            permissionService.ACCOUNTANT_ROLE,
            permissionService.TEACHING_MANAGER_ROLE,
          ],
          link: {
            name: 'sidebar.list',
            route: 'deals.archive'
          }
        }
      ]
    };

export default DealMenuItems;
