import Loading from 'vue-loading-overlay';

const VueFullLoading = {
    install(Vue) {
        // prevent duplicate instance on SSR
        if (!Vue.prototype.$fullLoading) {
            const custom = Vue.extend({
                template: `<div class="OctoLoading"><div class='loader loader1'><div>
                           <div><div><div><div><div></div></div></div></div></div></div></div></div>`
            });

            Vue.use(Loading);
            Vue.component('custom-loader', custom);
            Vue.prototype.$fullLoading = {
                localLoading: null,
                show: function () {
                    this.localLoading = Vue.$loading.show(
                        {},
                        {
                            default: (new Vue()).$createElement('custom-loader')
                        }
                    );
                },
                hide: function () {
                  if (this.localLoading) {
                    this.localLoading.hide();
                  }

                }
            }
        }
    },
};

export default VueFullLoading;
