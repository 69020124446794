export const getters = {

  phones: state => state?.registry?.phones || [],
  emails: state => state?.registry?.emails || [],
  addresses: state => state?.registry?.addresses || [],
  registry: state => state?.registry || {},
  provider: state => state?.provider || {},
  campaign: state => state?.campaign || {},
  courses: state => state?.courses || [],
  callCenterLead: state => state?.callCenterLead || {},
  lead: state => state?.lead || {},
  sales: state => state?.sales || {},
  leadEventLogs: state => state?.leadEventLogs || [],

  // data to send
  statusTo: state => state.status_to,
  subStatusTo: state => state.sub_status_to,
  deadline: state => state.deadline,
  saleAppointment: state => state.saleAppointment,
  skipIntro: state => state.skipIntro,
}
