import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import axios from 'axios';
import store from "@/store";

const VueEcho = {
    install(Vue) {
        // prevent duplicate instance on SSR
        if (!Vue.prototype.$echo) {

          const axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            headers: {'spa-version': process.env.VUE_APP_VERSION}
          });

          axiosInstance.interceptors.request.use(
            async(config) => {
              const token = store.state.auth.accessToken
              if (token) {
                config.headers.authorization = 'Bearer ' + token
              }
              return config
            },
            async(err) => {
              throw err
            }
          )

          const echo = new Echo({
            broadcaster: process.env.VUE_APP_ECHO_BROADCASTER,
            wsHost: window.location.hostname,
            wsPort: process.env.VUE_APP_ECHO_PORT,
            // wssHost: window.location.hostname,
            wssPort: process.env.VUE_APP_ECHO_PORT,
            key: process.env.VUE_APP_ECHO_PUSHER_KEY,
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            forceTLS: process.env.VUE_APP_ECHO_FORCE_TLS === 'enabled',
            authEndpoint: process.env.VUE_APP_API_URL + '/api/broadcasting/auth',
            authorizer: (channel, options) => {
              return {
                authorize: (socketId, callback) => {
                  axiosInstance.post(
                    process.env.VUE_APP_API_URL + '/api/broadcasting/auth',
                    {socket_id: socketId, channel_name: channel.name,}
                  )
                    .then((response) => {
                      callback(false, response.data);
                    })
                    .catch((error) => {
                      callback(true, error);
                    });
                },
              };
            },
          })

          Object.defineProperty(Vue.prototype, '$echo', { value: echo });
        }
    },
};

export default VueEcho;
