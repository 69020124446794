import Vue from "vue";
import {endpoints} from "@/routes/endpoints";
import _ from "lodash";

export const actions = {

  getFirstLead({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.CALL_CENTER_GET_FIRST_LEAD)
        .then((resp) => {
          commit('resetState');
          if(_.isEmpty(resp.data)) {
            reject();
          }
          commit('setCampaign', resp?.data?.campaign);
          commit('setCallCenterLead', resp?.data?.callCenterLead);
          commit('setProvider', resp?.data?.provider);
          commit('setRegistry', resp?.data?.registry);
          commit('setCourses', resp?.data?.courses);
          commit('setLead', resp?.data?.lead);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },

  handleLead({commit}, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.post(
        endpoints.CALL_CENTER_HANDLE_LEAD.replace('{id}', data.id),
        data.data
      )
        .then((resp) => {
          commit('resetState');
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },

  getLeadEventLogs({commit}, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.LEAD_GET_LEAD_EVENTS_LOG.replace('{id}', data.id))
        .then((resp) => {
          commit('setLeadEventLogs', resp?.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },

  getCampaignSales({commit}, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.CAMPAIGN_GET_CAMPAIGN_SALES.replace('{id}', data.id))
        .then((resp) => {
          commit('setSales', resp?.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
}
