import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const ReportsRoutes =
  {
    path: '/reports',
    name: 'reports',
    component: DashboardLayout,
    redirect: '/reports',
    children: [
      {
        path: '/',
        name: 'reports.list',
        components: {default: () => import('@/pages/Reports/IndexPage')},
        meta: {
          requiresAuth: true,
          title: 'reports_list',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: 'new-report-wizard',
        name: 'reports.new.wizard',
        components: {default: () => import('@/pages/Reports/WizardNewReportPage')},
        meta: {
          requiresAuth: true,
          title: 'reports_new_wizard',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: ':id',
        name: 'reports.show',
        components: {default: () => import('@/pages/Reports/ShowPage')},
        meta: {
          requiresAuth: true,
          title: 'reports_show',
          roles: [permissionService.ADMIN_ROLE]
        },
      }
    ]
  };

export default ReportsRoutes;
