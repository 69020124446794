<template>
  <i :class="`fi flaticon-${icon}`"></i>
</template>

<script>
export default {
  name: "OctoIcon",
  props: {
    icon: {
      default: 'car',
      type: String
    }
  },
}
</script>

<style scoped lang="scss">

$flaticon-font: "flaticon";

@font-face {
  font-family: $flaticon-font;
  src: url("./flaticon.ttf?4ab6f1cc960f12c58e5dbe6be4b72d03") format("truetype"),
  url("./flaticon.woff?4ab6f1cc960f12c58e5dbe6be4b72d03") format("woff"),
  url("./flaticon.woff2?4ab6f1cc960f12c58e5dbe6be4b72d03") format("woff2"),
  url("./flaticon.eot?4ab6f1cc960f12c58e5dbe6be4b72d03#iefix") format("embedded-opentype"),
  url("./flaticon.svg?4ab6f1cc960f12c58e5dbe6be4b72d03#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
  font-family: flaticon !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$flaticon-map: (
  "dashboard": "\f101",
  "crown": "\f102",
  "users": "\f103",
  "list": "\f104",
  "key": "\f105",
  "wrong": "\f106",
  "checked": "\f107",
  "sync": "\f108",
  "link": "\f109",
  "diskette": "\f10a",
  "add": "\f10b",
  "user": "\f10c",
  "hourglass": "\f10d",
  "settings": "\f10e",
  "padlock": "\f10f",
  "right-arrow": "\f110",
  "restore": "\f111",
  "upload": "\f112",
  "euro": "\f113",
  "broken-link": "\f114",
  "academic": "\f115",
  "filter": "\f116",
  "edit": "\f117",
  "headset": "\f118",
  "magnifying-glass": "\f119",
  "document": "\f11a",
  "annual-calendar-page": "\f11b",
  "telephone": "\f11c",
  "email": "\f11d",
  "two": "\f11e",
  "three": "\f11f",
  "four": "\f120",
  "five": "\f121",
  "one": "\f122",
  "clock": "\f123",
  "information": "\f124",
  "history": "\f125",
  "personal-data": "\f126",
  "placeholder": "\f127",
  "contact": "\f128",
  "statistics": "\f129",
  "telephone-1": "\f12a",
  "download": "\f12b",
  "sad": "\f12c",
  "smile": "\f12d",
  "meh": "\f12e",
  "sceptic": "\f12f",
  "sunrise": "\f130",
  "moon": "\f131",
  "sun": "\f132",
  "morning": "\f133",
  "study": "\f134",
  "presentation": "\f135",
  "calculator": "\f136",
  "check": "\f137",
  "circle": "\f138",
  "log-in": "\f139",
  "log-out": "\f13a",
  "cancel": "\f13b",
);

.flaticon-dashboard:before {
  content: map-get($flaticon-map, "dashboard");
}
.flaticon-crown:before {
  content: map-get($flaticon-map, "crown");
}
.flaticon-users:before {
  content: map-get($flaticon-map, "users");
}
.flaticon-list:before {
  content: map-get($flaticon-map, "list");
}
.flaticon-key:before {
  content: map-get($flaticon-map, "key");
}
.flaticon-wrong:before {
  content: map-get($flaticon-map, "wrong");
}
.flaticon-checked:before {
  content: map-get($flaticon-map, "checked");
}
.flaticon-sync:before {
  content: map-get($flaticon-map, "sync");
}
.flaticon-link:before {
  content: map-get($flaticon-map, "link");
}
.flaticon-diskette:before {
  content: map-get($flaticon-map, "diskette");
}
.flaticon-add:before {
  content: map-get($flaticon-map, "add");
}
.flaticon-user:before {
  content: map-get($flaticon-map, "user");
}
.flaticon-hourglass:before {
  content: map-get($flaticon-map, "hourglass");
}
.flaticon-settings:before {
  content: map-get($flaticon-map, "settings");
}
.flaticon-padlock:before {
  content: map-get($flaticon-map, "padlock");
}
.flaticon-right-arrow:before {
  content: map-get($flaticon-map, "right-arrow");
}
.flaticon-restore:before {
  content: map-get($flaticon-map, "restore");
}
.flaticon-upload:before {
  content: map-get($flaticon-map, "upload");
}
.flaticon-euro:before {
  content: map-get($flaticon-map, "euro");
}
.flaticon-broken-link:before {
  content: map-get($flaticon-map, "broken-link");
}
.flaticon-academic:before {
  content: map-get($flaticon-map, "academic");
}
.flaticon-filter:before {
  content: map-get($flaticon-map, "filter");
}
.flaticon-edit:before {
  content: map-get($flaticon-map, "edit");
}
.flaticon-headset:before {
  content: map-get($flaticon-map, "headset");
}
.flaticon-magnifying-glass:before {
  content: map-get($flaticon-map, "magnifying-glass");
}
.flaticon-document:before {
  content: map-get($flaticon-map, "document");
}
.flaticon-annual-calendar-page:before {
  content: map-get($flaticon-map, "annual-calendar-page");
}
.flaticon-telephone:before {
  content: map-get($flaticon-map, "telephone");
}
.flaticon-email:before {
  content: map-get($flaticon-map, "email");
}
.flaticon-two:before {
  content: map-get($flaticon-map, "two");
}
.flaticon-three:before {
  content: map-get($flaticon-map, "three");
}
.flaticon-four:before {
  content: map-get($flaticon-map, "four");
}
.flaticon-five:before {
  content: map-get($flaticon-map, "five");
}
.flaticon-one:before {
  content: map-get($flaticon-map, "one");
}
.flaticon-clock:before {
  content: map-get($flaticon-map, "clock");
}
.flaticon-information:before {
  content: map-get($flaticon-map, "information");
}
.flaticon-history:before {
  content: map-get($flaticon-map, "history");
}
.flaticon-personal-data:before {
  content: map-get($flaticon-map, "personal-data");
}
.flaticon-placeholder:before {
  content: map-get($flaticon-map, "placeholder");
}
.flaticon-contact:before {
  content: map-get($flaticon-map, "contact");
}
.flaticon-statistics:before {
  content: map-get($flaticon-map, "statistics");
}
.flaticon-telephone-1:before {
  content: map-get($flaticon-map, "telephone-1");
}
.flaticon-download:before {
  content: map-get($flaticon-map, "download");
}
.flaticon-sad:before {
  content: map-get($flaticon-map, "sad");
}
.flaticon-smile:before {
  content: map-get($flaticon-map, "smile");
}
.flaticon-meh:before {
  content: map-get($flaticon-map, "meh");
}
.flaticon-sceptic:before {
  content: map-get($flaticon-map, "sceptic");
}
.flaticon-sunrise:before {
  content: map-get($flaticon-map, "sunrise");
}
.flaticon-moon:before {
  content: map-get($flaticon-map, "moon");
}
.flaticon-sun:before {
  content: map-get($flaticon-map, "sun");
}
.flaticon-morning:before {
  content: map-get($flaticon-map, "morning");
}
.flaticon-study:before {
  content: map-get($flaticon-map, "study");
}
.flaticon-presentation:before {
  content: map-get($flaticon-map, "presentation");
}
.flaticon-calculator:before {
  content: map-get($flaticon-map, "calculator");
}
.flaticon-check:before {
  content: map-get($flaticon-map, "check");
}
.flaticon-circle:before {
  content: map-get($flaticon-map, "circle");
}
.flaticon-log-in:before {
  content: map-get($flaticon-map, "log-in");
}
.flaticon-log-out:before {
  content: map-get($flaticon-map, "log-out");
}
.flaticon-cancel:before {
  content: map-get($flaticon-map, "cancel");
}

</style>
