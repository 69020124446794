import {permissionService} from "@/util/permission-service";

const StudentMenuItems =
  {
    roles: [
      permissionService.TEACHING_MANAGER_ROLE,
      permissionService.TUTOR_ROLE,
    ],
    link: {
      name: 'sidebar.students',
      icon: 'users',
      route: 'students.index'
    },
    children: [
      {
        roles: [
          permissionService.TEACHING_MANAGER_ROLE
        ],
        link: {
          name: 'sidebar.new',
          route: 'students.wizard'
        }
      },
      {
        roles: [
          permissionService.TEACHING_MANAGER_ROLE
        ],
        link: {
          name: 'sidebar.in_progress_course',
          route: 'students.waiting.index'
        }
      },
      {
        roles: [
          permissionService.TUTOR_ROLE
        ],
        link: {
          name: 'sidebar.list',
          route: 'students.archive.index'
        }
      },
      {
        roles: [
          permissionService.TEACHING_MANAGER_ROLE
        ],
        link: {
          name: 'sidebar.bin',
          route: 'students.deleted.index'
        }
      },
    ]
  };

export default StudentMenuItems;
