import {actions} from './actions'
import {getters} from './getters'
import {mutations} from './mutations'
import _ from "lodash";
import User from "@/models/user";

const state = {
  currentUser: _.cloneDeep(User),
  roles: [],
  accessToken: null
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
