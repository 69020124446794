import {actions} from './actions'
import {getters} from './getters'
import {mutations} from './mutations'

const state = {
  settings: {},
  datatables: {},
  datatableOptions: {},
  datatablesData: {},
  countries: [],
  regions: [],
  provinces: [],
  users: [],
  campaigns: [],
  courses: [],
  providers: [],
  helper: null,
  broadcastEvent: {},
  subjects: [],
  locations: [],
  courseModules: [],
  emailTemplates: []
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
