import DashboardLayout from '@/pages/Layout/DashboardLayout.vue';
import {permissionService} from "@/util/permission-service";

const UserRoutes =
  {
    path: '/users',
    name: 'users',
    component: DashboardLayout,
    redirect: '/users',
    children: [
      {
        path: '/',
        name: 'users.index',
        components: {default: () => import('@/pages/Users/IndexPage')},
        meta: {
          requiresAuth: true,
          title: 'users',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'profile',
        name: 'users.profile',
        components: {default: () => import('@/pages/Users/ProfilePage')},
        meta: {
          requiresAuth: true,
          title: 'user',
          roles: []
        },
      },
      {
        path: 'operators',
        name: 'users.operators.index',
        components: {default: () => import('@/pages/CallCenter/IndexOperatorPage')},
        meta: {
          requiresAuth: true,
          title: 'call_center_operators',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'operators/:id',
        name: 'users.operators.show',
        components: {default: () => import('@/pages/CallCenter/ShowOperatorPage')},
        meta: {
          requiresAuth: true,
          title: 'call_center_operator',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'sales',
        name: 'users.sales.index',
        components: {default: () => import('@/pages/Deals/SalesIndexPage')},
        meta: {
          requiresAuth: true,
          title: 'sales',
          roles: [permissionService.SALES_MANAGER_ROLE]
        },
      },
      {
        path: 'sales/:id',
        name: 'users.sales.show',
        components: {default: () => import('@/pages/Deals/ShowSalesPage')},
        meta: {
          requiresAuth: true,
          title: 'sales_singular',
          roles: [permissionService.SALES_MANAGER_ROLE]
        },
      },
      {
        path: 'tutors',
        name: 'users.tutors.index',
        components: {default: () => import('@/pages/Didactics/IndexTutorPage')},
        meta: {
          requiresAuth: true,
          title: 'tutors',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'tutors/:id',
        name: 'users.tutors.show',
        components: {default: () => import('@/pages/Didactics/ShowTutorPage')},
        meta: {
          requiresAuth: true,
          title: 'tutor',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'wizard',
        name: 'users.wizard',
        components: {default: () => import('@/pages/Users/WizardPage')},
        meta: {
          requiresAuth: true,
          title: 'users',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: ':id',
        name: 'users.show',
        components: {default: () => import('@/pages/Users/ShowPage')},
        meta: {
          requiresAuth: true,
          title: 'user',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
    ]
  };

export default UserRoutes;
