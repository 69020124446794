import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {

  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const items = key.replace(/(\.\/|\.json)/g, '').split('_')
    const locale = items[1];
    const namespace = items[0];
    if(!messages[locale]) {
      messages[locale] = {};
    }
    messages[locale][namespace] = locales(key);
  });

  return messages;

}


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'it',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'it',
  messages: loadLocaleMessages()
});
