import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const LeadRoutes =
  {
    path: '/leads',
    name: 'leads',
    component: DashboardLayout,
    redirect: '/leads/archive',
    children: [
      {
        path: 'dashboard',
        name: 'leads.dashboard',
        components: {default: () => import('@/pages/Leads/DashboardView')},
        meta: {
          requiresAuth: true,
          title: 'dashboard',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE]
        },
      },
      {
        path: 'archive',
        name: 'leads.archive',
        components: {default: () => import('@/pages/Leads/IndexLeadArchivePage')},
        meta: {
          requiresAuth: true,
          title: 'lead_archive',
          roles: [
            permissionService.CALL_CENTER_MANAGER_ROLE,
            permissionService.CALL_CENTER_OPERATOR_ROLE
          ]
        },
      },
      {
        path: 'archive/:id',
        name: 'leads.archive.show',
        components: {default: () => import('@/pages/Leads/ShowLeadArchivePage')},
        meta: {
          requiresAuth: true,
          title: 'lead',
          roles: [
            permissionService.CALL_CENTER_MANAGER_ROLE,
            permissionService.CALL_CENTER_OPERATOR_ROLE
          ]
        },
      },
      {
        path: 'campaigns',
        name: 'leads.campaigns',
        components: {default: () => import('@/pages/Leads/IndexCampaignPage')},
        meta: {
          requiresAuth: true,
          title: 'campaigns',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'campaigns/wizard',
        name: 'leads.campaigns.wizard',
        components: {default: () => import('@/pages/Leads/CampaignWizardPage')},
        meta: {
          requiresAuth: true,
          title: 'campaigns_wizard',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'campaigns/:id',
        name: 'leads.campaigns.show',
        components: {default: () => import('@/pages/Leads/ShowCampaignPage')},
        meta: {
          requiresAuth: true,
          title: 'campaigns',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'in-progress',
        name: 'leads.in_progress',
        components: {default: () => import('@/pages/Leads/IndexLeadProgressPage')},
        meta: {
          requiresAuth: true,
          title: 'lead_in_progress',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },

      {
        path: 'in-progress/wizard',
        name: 'leads.in_progress.wizard',
        components: {default: () => import('@/pages/Leads/LeadProgressWizardPage')},
        meta: {
          requiresAuth: true,
          title: 'lead_in_progress_wizard',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'in-progress/:id',
        name: 'leads.in_progress.show',
        components: {default: () => import('@/pages/Leads/ShowLeadProgressPage')},
        meta: {
          requiresAuth: true,
          title: 'lead_in_progress',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
    ]
  };

export default LeadRoutes;
