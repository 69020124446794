import {permissionService} from "@/util/permission-service";

const DidacticsMenuItems = {
  roles: [
    permissionService.ADMIN_ROLE,
    permissionService.TEACHING_MANAGER_ROLE,
    permissionService.TUTOR_ROLE
  ],
  link: {
    name: 'sidebar.didactics',
    icon: 'presentation',
  },
  children: [
    {
      roles: [
        permissionService.TEACHING_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.dashboard',
        route: 'didactics.dashboard'
      }
    },
    {
      roles: [
        permissionService.TEACHING_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.classroom_pending',
        route: 'didactics.classrooms.pending.index'
      }
    },
    {
      roles: [
        permissionService.TUTOR_ROLE
      ],
      link: {
        name: 'sidebar.classroom_archive',
        route: 'didactics.classrooms.index'
      }
    },
    {
      roles: [
        permissionService.TUTOR_ROLE
      ],
      link: {
        name: 'sidebar.lessons',
        route: 'didactics.lessons.index'
      }
    }
  ]
};

export default DidacticsMenuItems;
