import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const DealRoutes =
  {
    path: '/deals',
    name: 'deals.main',
    component: DashboardLayout,
    redirect: '/deals/archive',
    children: [
      {
        path: 'dashboard',
        name: 'deals.dashboard',
        components: {default: () => import('@/pages/Deals/DashboardView')},
        meta: {
          requiresAuth: true,
          title: 'dashboard',
          roles: [permissionService.SALES_MANAGER_ROLE]
        },
      },
      {
        path: 'in-progress',
        name: 'deals.in_progress',
        components: {default: () => import('@/pages/Deals/IndexDealsInProgressPage')},
        meta: {
          requiresAuth: true,
          title: 'deal_in_progress',
          roles: [permissionService.SALES_ROLE]
        },
      },
      {
        path: 'in-progress/:id',
        name: 'deals.in_progress.show',
        components: {default: () => import('@/pages/Deals/ShowDealInProgressPage')},
        meta: {
          requiresAuth: true,
          title: 'deal',
          roles: [permissionService.SALES_ROLE]
        },
      },
      {
        path: 'to-recall/:id',
        name: 'deals.to_recall.show',
        components: {default: () => import('@/pages/Deals/ShowDealToRecallPage')},
        meta: {
          requiresAuth: true,
          title: 'deal',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE]
        },
      },
      {
        path: 'archive',
        name: 'deals.archive',
        components: {default: () => import('@/pages/Deals/IndexPage')},
        meta: {
          requiresAuth: true,
          title: 'deal_archive',
          roles: [
            permissionService.CALL_CENTER_OPERATOR_ROLE,
            permissionService.SALES_ROLE,
            permissionService.ACCOUNTANT_ROLE,
            permissionService.TEACHING_MANAGER_ROLE
          ]
        },
      },
      {
        path: 'archive/:id',
        name: 'deals.archive.show',
        components: {default: () => import('@/pages/Deals/ShowDealArchivePage')},
        meta: {
          requiresAuth: true,
          title: 'deal_archive',
          roles: [
            permissionService.CALL_CENTER_OPERATOR_ROLE,
            permissionService.SALES_ROLE,
            permissionService.ACCOUNTANT_ROLE,
            permissionService.TEACHING_MANAGER_ROLE
          ]
        },
      },
      {
        path: 'wizard',
        name: 'deals.wizard',
        components: {default: () => import('@/pages/Deals/WizardPage')},
        meta: {
          requiresAuth: true,
          title: 'deal_wizard',
          roles: [permissionService.SALES_MANAGER_ROLE,  permissionService.SALES_ROLE]
        },
      },
      {
        path: 'to-confirm',
        name: 'deals.to_confirm',
        components: {default: () => import('@/pages/Deals/IndexDealsToConfirmPage')},
        meta: {
          requiresAuth: true,
          title: 'deal_to_confirm',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE]
        },
      },
      {
        path: 'sales-diary',
        name: 'deals.sales-diary',
        components: {default: () => import('@/pages/Deals/SalesDiaryPage')},
        meta: {
          requiresAuth: true,
          title: 'sales_diary',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE]
        },
      },
      {
        path: 'to-confirm/:id',
        name: 'deals.to_confirm.show',
        components: {default: () => import('@/pages/Deals/ShowDealToConfirmPage')},
        meta: {
          requiresAuth: true,
          title: 'deal_to_confirm_detail',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE, permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'to-recall',
        name: 'deals.to_recall',
        components: {default: () => import('@/pages/Deals/IndexDealsToRecall')},
        meta: {
          requiresAuth: true,
          title: 'deals_to_recall',
          roles: [permissionService.CALL_CENTER_OPERATOR_ROLE]
        },
      },
      {
        path: 'expired',
        name: 'deals.expired',
        components: {default: () => import('@/pages/Deals/IndexDealsExpired')},
        meta: {
          requiresAuth: true,
          title: 'deals_expired',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'expired/:id',
        name: 'deals.expired.show',
        components: {default: () => import('@/pages/Deals/ShowDealExpiredPage')},
        meta: {
          requiresAuth: true,
          title: 'deal_expired_detail',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
    ]
  };

export default DealRoutes;
