
const DealDate = {
  id: null,
  date_end: null,
  date_start: null,
  status: null,
  user_id: null,
  text: null,
  dateable_type: null,
  dateable_id: null,
  location: null
};

export default DealDate
