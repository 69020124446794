import {permissionService} from "@/util/permission-service";

const CourseMenuItems = {
  roles: [
    permissionService.TEACHING_MANAGER_ROLE
  ],
  link: {
    name: 'sidebar.didactics_catalog',
    icon: 'academic'
  },
  children: [
    {
      roles: [
        permissionService.TEACHING_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.courses',
        route: 'courses.index'
      }
    },
    {
      roles: [
        permissionService.TEACHING_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.certificates',
        route: 'courses.certificates.index'
      }
    },
    {
      roles: [
        permissionService.TEACHING_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.workshops',
        route: 'courses.workshops.index'
      }
    },
  ]
};

export default CourseMenuItems;
