import {permissionService} from "@/util/permission-service";

const BalancesMenuItems = {
  roles: [
    permissionService.ADMIN_ROLE,
    permissionService.CALL_CENTER_MANAGER_ROLE
  ],
  link: {
    name: 'sidebar.balances',
    icon: 'calculator'
  },
  children: [
    {
      roles: [
        permissionService.ADMIN_ROLE
      ],
      link: {
        name: 'sidebar.preview',
        route: 'balances.preview'
      }
    },
    {
      roles: [
        permissionService.ADMIN_ROLE
      ],
      link: {
        name: 'sidebar.commissions',
        route: 'balances.commissions'
      }
    }
  ]
};

export default BalancesMenuItems;
