import {actions} from './actions'
import {getters} from './getters'
import {mutations} from './mutations'
import Campaign from "@/models/campaign";
import CallCenterLead from "@/models/callCenterLead";
// import LeadStatusTransition from "@/models/leadStatusTransition";
import Provider from "@/models/provider";
import Registry from "@/models/registry";
import Lead from "@/models/lead";
import DealDate from "@/models/dealDate";

const state = {
  campaign: _.cloneDeep(Campaign),
  callCenterLead: _.cloneDeep(CallCenterLead),
  provider: _.cloneDeep(Provider),
  registry: _.cloneDeep(Registry),
  // leadStatusTransition: _.cloneDeep(LeadStatusTransition),
  lead: _.cloneDeep(Lead),
  courses: [],
  leadEventLogs: [],
  sales: [],
  status_to: null,
  sub_status_to: null,
  deadline: null,
  saleAppointment: _.cloneDeep(DealDate),
  skipIntro: false


};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
