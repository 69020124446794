<template>
  <el-tooltip
    content="Sidebar toggle"
    effect="light"
    :open-delay="300"
    placement="right"
  >
    <button
      class="minimize-sidebar btn btn-link btn-just-icon"
      @click="minimizeSidebar"
      rel="tooltip"
      data-original-title="Sidebar toggle"
      data-placement="right"
    >
      <i class="tim-icons icon-align-center visible-on-sidebar-regular"></i>
      <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i>
    </button>
  </el-tooltip>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'sidebar-toggle-button',
  computed: {
    ...mapGetters({
      sidebarStatus: 'auth/sidebarStatus',
    }),
  },
  methods: {
    ...mapMutations({
      setSidebarStatus: 'auth/setSidebarStatus'
    }),
    minimizeSidebar() {
      this.setSidebarStatus(this.sidebarStatus === 'extended' ? 'mini' : 'extended');
      this.$sidebar.toggleMinimize();
    }
  }
};
</script>
<style></style>
