const endpoints = {
  SANCTUM_CSRF_COOKIE: 'sanctum/csrf-cookie',

  /** USER END POINTS **/
  USER_LOGIN: 'api/admin/v1/login',
  USER_LOGOUT: 'api/admin/v1/logout',
  USER_STORE: 'api/admin/v1/users',
  USER_UPDATE: 'api/admin/v1/users/{id}',
  USER_UPDATE_ROLES: 'api/admin/v1/users/{id}/roles',
  USER_UPDATE_OPERATOR: 'api/admin/v1/users/{id}/operators',
  USER_UPDATE_SALES: 'api/admin/v1/users/{id}/sales',
  USER_UPDATE_TUTOR: 'api/admin/v1/users/{id}/tutors',
  USER_SHOW: 'api/admin/v1/users/{id}',
  USER_GENERATE_NEW_PASSWORD: 'api/admin/v1/users/{id}/generate-new-password',
  USER_CURRENT: 'api/admin/v1/users/current',
  USER_UPDATE_PASSWORD: 'api/admin/v1/users/update-password',
  USER_UPDATE_THEME_PREFERENCES: 'api/admin/v1/users/update-theme-preferences',
  USER_RECOVERY_PASSWORD: 'api/admin/v1/recovery-password',
  USER_INDEX: 'api/admin/v1/users',
  USER_INDEX_BY_ROLE: 'api/admin/v1/users/by-role/{role}/index',
  USER_INDEX_BY_ROLE_MONTH_YEAR: 'api/admin/v1/users/by-role/{role}/index/{month}/{year}',
  USER_SHOW_SALES: 'api/admin/v1/users/{id}/sales',
  USER_SHOW_OPERATOR: 'api/admin/v1/users/{id}/operator',
  USER_SHOW_TUTOR: 'api/admin/v1/users/{id}/tutors',
  USER_UPDATE_SALARIES: 'api/admin/v1/call-center/operator/users-salaries',
  USER_UPDATE_PROFILES: 'api/admin/v1/call-center/operator/users-profiles',
  USER_MEDIA_PRESIGNED_URL: 'api/admin/v1/users/{id}/media/presigned-url',
  USER_STORE_MEDIA: 'api/admin/v1/users/{id}/media',

  /** REGISTRIES **/
  PROVIDER_IMPORT_UPLOAD: 'api/admin/v1/providers/imports/upload',
  PROVIDER_IMPORT_SHOW: 'api/admin/v1/providers/imports/{id}',
  PROVIDER_IMPORT_REGISTRY_SYNC: 'api/admin/v1/providers/imports/{id}/registry-sync',
  PROVIDER_IMPORT_STATS: 'api/admin/v1/providers/imports/{id}/statistics',
  REGISTRY_STORE: 'api/admin/v1/registries',
  REGISTRY_UPDATE: 'api/admin/v1/registries/{id}',
  REGISTRY_SHOW: 'api/admin/v1/registries/{id}',
  REGISTRY_GET_PROVIDER_IMPORT_DATA: 'api/admin/v1/registries/{id}/provider-import-data',
  REGISTRY_GET_LEADS: 'api/admin/v1/registries/{id}/leads',
  REGISTRY_GET_DEALS: 'api/admin/v1/registries/{id}/deals',
  REGISTRY_GET_TIMELINES: 'api/admin/v1/registries/{id}/timelines',
  REGISTRY_UPDATE_EMAILS: 'api/admin/v1/registries/{id}/emails',
  REGISTRY_UPDATE_PHONES: 'api/admin/v1/registries/{id}/phones',
  REGISTRY_UPDATE_ADDRESSES: 'api/admin/v1/registries/{id}/addresses',
  REGISTRY_UPDATE_PERSONAL_DATA: 'api/admin/v1/registries/{id}/personal-data',
  REGISTRY_ENABLED_CERTIFICATIONS: 'api/admin/v1/registries/{id}/enabled-certifications',
  REGISTRY_SEARCH_REGISTRIES_BY_NAME: 'api/admin/v1/registries/search-registries-by-name',

  /** PROVIDER **/
  PROVIDER_INDEX: "api/admin/v1/providers",
  PROVIDER_SHOW: "api/admin/v1/providers/{id}",
  PROVIDER_STORE: "api/admin/v1/providers",
  PROVIDER_UPDATE: "api/admin/v1/providers/{id}",
  PROVIDER_DATA_IMPORT_UPDATE: "api/admin/v1/providers/imports/{id}/sync-provider-course",
  PROVIDER_IMPORT_DELETE: "api/admin/v1/providers/imports/{id}",
  PROVIDER_BALANCE_STATS: "api/admin/v1/providers/{id}/balance-stats/{year}/{month}",
  PROVIDER_SYNC_COURSE_CUSTOM_SLUG: "api/admin/v1/providers/{id}/courses/{courseId}/sync-provider-custom-course-slug",
  PROVIDER_DETACH_COURSE_CUSTOM_SLUG: "api/admin/v1/providers/{id}/courses/{courseId}/detach-provider-custom-course-slug",

  /** CONFIG END POINTS **/
  CONFIG_MODULES_INDEX: 'api/admin/v1/modules',
  CONFIG_SETTINGS_INDEX: 'api/admin/v1/settings',
  CONFIG_SETTINGS_POST: 'api/admin/v1/settings',
  CONFIG_SETTINGS_PUT: 'api/admin/v1/settings',

  /** ROLES **/
  ROLE_INDEX: 'api/admin/v1/roles',

  /** COURSES **/
  COURSE_INDEX: 'api/admin/v1/courses',
  COURSE_STORE: 'api/admin/v1/courses',
  COURSE_UPDATE: 'api/admin/v1/courses/{id}',
  COURSE_SHOW: 'api/admin/v1/courses/{id}',
  COURSE_SYNC_COURSE_MODULES: 'api/admin/v1/courses/{id}/sync-course-modules',
  COURSE_SYNC_TUTORS: 'api/admin/v1/courses/{id}/sync-tutors',
  COURSE_SYNC_COURSES: 'api/admin/v1/courses/{id}/sync-courses',
  COURSE_MODULE_INDEX: 'api/admin/v1/courses/modules',
  COURSE_MODULE_STORE: 'api/admin/v1/courses/modules',
  COURSE_MODULE_UPDATE: 'api/admin/v1/courses/modules/{id}',
  COURSE_MODULE_SHOW: 'api/admin/v1/courses/modules/{id}',
  COURSE_SUBJECT_INDEX: 'api/admin/v1/courses/subjects',
  COURSE_SUBJECT_STORE: 'api/admin/v1/courses/subjects',
  COURSE_SUBJECT_UPDATE: 'api/admin/v1/courses/subjects/{id}',
  COURSE_SEARCH_STUDENTS: 'api/admin/v1/courses/{id}/search-students',

  /** CAMPAIGNS **/
  CAMPAIGN_GET_FILTERS: 'api/admin/v1/leads/campaigns/filters',
  CAMPAIGN_STORE: 'api/admin/v1/leads/campaigns',
  CAMPAIGN_UPDATE: 'api/admin/v1/leads/campaigns/{id}',
  CAMPAIGN_UPDATE_VISIBILITY: 'api/admin/v1/leads/campaigns/{id}/visibility',
  CAMPAIGN_SHOW: 'api/admin/v1/leads/campaigns/{id}',
  CAMPAIGN_ACTIVATE: 'api/admin/v1/leads/campaigns/{id}/activate',
  CAMPAIGN_DEACTIVATE: 'api/admin/v1/leads/campaigns/{id}/deactivate',
  CAMPAIGN_WIZARD_REPORT: 'api/admin/v1/leads/campaigns/wizard-report',
  CAMPAIGN_GET_CAMPAIGN_SALES: 'api/admin/v1/leads/campaigns/{id}/get-campaign-sales',
  CAMPAIGN_BY_OPERATOR: 'api/admin/v1/leads/campaigns/by-operator',
  CAMPAIGN_INDEX: 'api/admin/v1/leads/campaigns',

  /** LEADS **/
  LEAD_GET_LEAD_EVENTS_LOG: 'api/admin/v1/leads/{id}/lead-event-logs',
  LEAD_SHOW: 'api/admin/v1/leads/{id}',
  LEAD_UPDATE_NOTES: 'api/admin/v1/leads/{id}/update-notes',
  LEAD_CHANGE_OPERATOR: 'api/admin/v1/leads/{id}/users/{operatorId}/change-operator',
  LEAD_GET_LEAD_BALANCE_SUMMARY_BY_MONTH: 'api/admin/v1/leads/{year}/{month}/balance-summary-by-month',
  LEAD_REACTIVATE: 'api/admin/v1/leads/{id}/reactivate-lead',

  CALL_CENTER_CALL_START: 'api/admin/v1/call-center-calls/start-call',
  CALL_CENTER_CALL_END: 'api/admin/v1/call-center-calls/{id}/end-call',

  /** CALL CENTER **/
  CALL_CENTER_TIMESHEET_OPERATOR_YEARLY: 'api/admin/v1/call-center/operator/{id}/{year}',
  CALL_CENTER_TIMESHEET_OPERATOR_MONTHLY_STORE: 'api/admin/v1/call-center/operator/{id}/{year}/{month}',
  CALL_CENTER_TIMESHEET_OPERATOR_MONTHLY_SHOW: 'api/admin/v1/call-center/operator/{id}/{year}/{month}',
  CALL_CENTER_TIMESHEET_OPERATOR_DAILY_STORE: 'api/admin/v1/call-center/operator/daily',
  CALL_CENTER_TIMESHEET_OPERATOR_DAILY_INDEX: 'api/admin/v1/call-center/operator/{year}/{month}/{day}',
  CALL_CENTER_SALARY_OPERATOR_MONTHLY_STORE: 'api/admin/v1/call-center/operator/salaries/{id}/{year}/{month}',
  CALL_CENTER_SALARY_OPERATOR_MONTHLY_SHOW: 'api/admin/v1/call-center/operator/salaries/{id}/{year}/{month}',
  CALL_CENTER_GET_FIRST_LEAD: 'api/admin/v1/call-center/operator/get-first-lead',
  CALL_CENTER_HANDLE_LEAD: 'api/admin/v1/call-center/operator/{id}/handle-lead',
  CALL_CENTER_SEND_EMAIL: 'api/admin/v1/call-center/operator/{id}/send-email',
  CALL_CENTER_GET_LEAD_BY_PHONE: 'api/admin/v1/call-center/operator/lead-by-phone',
  CALL_CENTER_GET_PRIVATE_RECALLS: 'api/admin/v1/call-center/operator/get-call-center-lead-private-recalls',
  CALL_CENTER_MANAGE_FREE_LEAD: 'api/admin/v1/call-center/operator/{id}/manage-free-lead',
  CALL_CENTER_LEAD_FROM_REGISTRY: 'api/admin/v1/call-center/registries/{registryId}/campaigns/{campaignId}' +
    '/create-call-center-lead',
  CALL_CENTER_LEAD_SHOW: 'api/admin/v1/call-center/call-center-leads/{id}',
  CALL_CENTER_STORE_TEMP_ATTACHMENT: 'api/admin/v1/call-center/operator/store-temp-attachment',
  CALL_CENTER_LEAD_UPDATE: 'api/admin/v1/call-center/call-center-leads/{id}',
  CALL_CENTER_LEAD_RELEASE: 'api/admin/v1/call-center/call-center-leads/{id}/release',
  CALL_CENTER_LEAD_ASSIGN_FREE: 'api/admin/v1/call-center/call-center-leads/{id}/assign-free-call-center-lead',

  /** TIMESHEETS **/
  TIMESHEET_INDEX: 'api/admin/v1/timesheets',

  /** DEALS */
  DEAL_SHOW: 'api/admin/v1/deals/{id}',
  DEAL_UPDATE: 'api/admin/v1/deals/{id}',
  DEAL_UPDATE_CONTRACT_DATA: 'api/admin/v1/deals/{id}/contract-data',

  DEAL_SYNC_DEAL_DETAILS: 'api/admin/v1/deals/{id}/sync-deal-details',
  DEAL_ARCHIVE_UPDATE: 'api/admin/v1/deals/{id}/archive',
  DEAL_TO_APPROVE_UPDATE: 'api/admin/v1/deals/{id}/to-approve',
  DEAL_TO_CONFIRM_UPDATE: 'api/admin/v1/deals/{id}/to-confirm',
  DEAL_IN_PROGRESS_UPDATE: 'api/admin/v1/deals/{id}/in-progress',
  DEAL_TO_RECALL_UPDATE: 'api/admin/v1/deals/{id}/to-recall',
  DEAL_EXPIRED_UPDATE: 'api/admin/v1/deals/{id}/expired',
  DEAL_DOCUMENT_PRESIGNED_URL: 'api/admin/v1/deals/{id}/get-document-presigned-url',
  DEAL_GET_STATUS_TRANSITIONS: 'api/admin/v1/deals/{id}/deal-status-transitions',
  DEAL_GET_EVENT_LOGS: 'api/admin/v1/deals/{id}/deal-event-logs',
  DEAL_SYNC_PAYMENTS: 'api/admin/v1/deals/{id}/sync-payments',
  DEAL_STORE_DOCUMENT: 'api/admin/v1/deals/{id}/store-document',
  DEAL_FROM_REGISTRY: 'api/admin/v1/deals/registries/{registryId}/create-deal-from-registry',
  DEAL_GET_DEAL_BALANCE_SUMMARY_BY_MONTH: 'api/admin/v1/deals/{year}/{month}/balance-summary-by-month',
  DEAL_STUDENTS: 'api/admin/v1/deals/{id}/students',

  /** POST SELLING **/
  POST_SELLING_UPDATE_ACCOUNTING_DATA: 'api/admin/v1/post-selling/deals/{id}/accounting-data',
  PAYMENT_STORE_BY_DEAL: 'api/admin/v1/post-selling/payments/deals/{dealId}/store-by-deal',
  PAYMENT_GENERATE_PAYMENTS_BY_DEAL: 'api/admin/v1/post-selling/payments/deals/{dealId}/generate-payments-by-deal',
  PAYMENT_UPDATE: 'api/admin/v1/post-selling/payments/{id}',
  PAYMENT_DELETE: 'api/admin/v1/post-selling/payments/{id}',
  DEAL_HOLDER_STORE_BY_DEAL: 'api/admin/v1/post-selling/deal-holders/deals/{dealId}/store-by-deal',
  DEAL_HOLDER_UPDATE: 'api/admin/v1/post-selling/deal-holders/{id}',
  DEAL_HOLDER_DELETE: 'api/admin/v1/post-selling/deal-holders/{id}',
  POST_SELLING_SHOW: 'api/admin/v1/post-selling/deals/{id}',
  DEAL_FINANCIAL_HOLDER_STORE_BY_DEAL: 'api/admin/v1/post-selling/deal-financial-holders/deals/{dealId}/store-by-deal',
  DEAL_FINANCIAL_HOLDER_UPDATE: 'api/admin/v1/post-selling/deal-financial-holders/{id}',
  DEAL_FINANCIAL_HOLDER_DELETE: 'api/admin/v1/post-selling/deal-financial-holders/{id}',
  POST_SELLING_DEBT_COLLECTION_INDEX: 'api/admin/v1/post-selling/debt-collections',
  POST_SELLING_DEBT_COLLECTION_SEND_TO_LAWYER: 'api/admin/v1/post-selling/debt-collections/send-to-lawyer',

  /** DEAL DATES */
  DEAL_DATE_UPDATE: 'api/admin/v1/deal-dates/{id}',
  DEAL_DATE_DELETE: 'api/admin/v1/deal-dates/{id}',
  DEAL_DATE_CHANGE_OWNERSHIP: 'api/admin/v1/deal-dates/{id}/change-sale-ownership',
  DEAL_DATE_UPDATE_APPOINTMENT_AND_LEAD_NOTES: 'api/admin/v1/deal-dates/{id}/update-appointment-and-lead-notes',
  DEAL_DATE_INDEX_DIARY: 'api/admin/v1/deal-dates/diary',
  DEAL_DATE_OPERATOR_INDEX_DIARY: 'api/admin/v1/deal-dates/operators-diary',
  DEAL_DATE_STORE_TEMP: 'api/admin/v1/deal-dates/temp',
  DEAL_DATE_INDEX_CHECK_NOTES: 'api/admin/v1/deal-dates/check-notes/{date}',
  DEAL_DATE_INDEX_BY_USER: 'api/admin/v1/deal-dates/by-user',
  DEAL_DATE_STORE_BY_CALL_CENTER_LEAD: 'api/admin/v1/deal-dates/store-by-call-center-leads',
  DEAL_DATE_STORE_BY_DEAL: 'api/admin/v1/deal-dates/store-by-deal',
  DEAL_DATE_STORE_APPOINTMENT_BLOCK: 'api/admin/v1/deal-dates/store-appointment-block',

  DATATABLES: {
    users: 'api/admin/v1/datatables/core/users',
    registries: 'api/admin/v1/datatables/core/registries',
    providers: 'api/admin/v1/datatables/core/providers',
    providerImportData: 'api/admin/v1/datatables/core/provider-import/{id}/data',
    providerImportDataMissmatch: 'api/admin/v1/datatables/core/provider-import/{id}/data-missmatch',
    userEventLogAccesses: 'api/admin/v1/datatables/core/user-event-logs/{id}/accesses',
    providerImport: '/api/admin/v1/datatables/core/provider-import',
    campaigns: 'api/admin/v1/datatables/campaigns',
    callCenterLeads: 'api/admin/v1/datatables/call-center-leads',
    callCenterEmails: 'api/admin/v1/datatables/call-center-emails',
    callCenterTimesheets: 'api/admin/v1/datatables/call-center-timesheets',
    leads: 'api/admin/v1/datatables/lead/leads',
    subjects: 'api/admin/v1/datatables/subjects',
    courses: 'api/admin/v1/datatables/courses',
    courseModules: 'api/admin/v1/datatables/course-modules',
    deals: 'api/admin/v1/datatables/deals',
    deals_financing: 'api/admin/v1/datatables/deals/financing',
    deals_to_confirm: 'api/admin/v1/datatables/deals/to-confirm',
    sales: 'api/admin/v1/datatables/core/sales',
    dealsInProgress: 'api/admin/v1/datatables/deals/in-progress',
    dealsToRecall: 'api/admin/v1/datatables/deals/to-recall',
    dealsClosed: 'api/admin/v1/datatables/deals/closed',
    dealsExpired: 'api/admin/v1/datatables/deals/expired',
    operators: 'api/admin/v1/datatables/core/operators',
    reports: 'api/admin/v1/datatables/reports',
    students: 'api/admin/v1/datatables/didactics/students',
    studentsDeleted: 'api/admin/v1/datatables/didactics/students/deleted',
    studentsInProgressCertificate: 'api/admin/v1/datatables/didactics/students/in-progress/certificate',
    studentsInProgressCourse: 'api/admin/v1/datatables/didactics/students/in-progress/course',
    studentsInProgress: 'api/admin/v1/datatables/didactics/students/in-progress',
    tutors: 'api/admin/v1/datatables/core/tutors',
    classrooms: 'api/admin/v1/datatables/didactics/classrooms',
    commissionDealsCompetenceDateMonth: 'api/admin/v1/datatables/commissions/deals/{year}/{month}/competence-date',
    commissionDealsConfirmedDateMonth: 'api/admin/v1/datatables/commissions/deals/{year}/{month}/confirmed-date',
    commissionDealsEnrollmentsMonth: 'api/admin/v1/datatables/commissions/deals/{year}/{month}/enrollments',
    commissionDealsCallCenterMonth: 'api/admin/v1/datatables/commissions/deals/{year}/{month}/call-center',
    commissionLeadsWIthAppointment: 'api/admin/v1/datatables/commissions/leads/{year}/{month}/with-appointment',
    commissionIndex: 'api/admin/v1/datatables/commissions',
    lessons: 'api/admin/v1/datatables/didactics/lessons',
    payments: 'api/admin/v1/datatables/post-selling/payments',
    financings: 'api/admin/v1/datatables/post-selling/financings',
    locations: 'api/admin/v1/datatables/didactics/locations',
    availableGuestClassrooms: 'api/admin/v1/datatables/didactics/students/{id}/guest-classrooms',
    emailTemplates: 'api/admin/v2/datatables/core/email-templates',
    saleThresholds: 'api/admin/v1/datatables/commissions/sale_thresholds',
    saleManagerCommissions: 'api/admin/v1/datatables/commissions/sale_manager_commissions',
    clients: 'api/admin/v1/datatables/core/clients',
    providerCourse: 'api/admin/v1/datatables/core/provider-course',
    emailArchive: 'api/admin/v1/datatables/core/email-archive',
    providerImportDataExternal: 'api/admin/v1/datatables/core/provider-import-data/external',
    zohoReports: 'api/admin/v1/zoho/reports',
    postSellings: 'api/admin/v1/datatables/post-selling',
    sentToLawyer: 'api/admin/v1/datatables/post-selling/debt-collections/sent-to-lawyer',
  },

  CHARTS: {
    importRegistriesByDate: 'api/admin/v1/charts/import-registries',
    callCenterLeadVsCourse: 'api/admin/v1/charts/call-center-leads/courses',
    callCenterLeadVsCampaign: 'api/admin/v1/charts/call-center-leads/campaigns',
    callCenterLeadVsProvider: 'api/admin/v1/charts/call-center-leads/providers',
    callCenterLeadVsOperator: 'api/admin/v1/charts/call-center-leads/operators',
    studentVsClassroomPending: 'api/admin/v1/charts/students/classrooms-pending',
    studentVsCourse: 'api/admin/v1/charts/students/courses',
    studentAttendingVsCourse: 'api/admin/v1/charts/students/attending/courses',
    dealLastSixMonthsContractAmounts: 'api/admin/v1/charts/deals/last-six-months-contract-amounts',
    dealPromotedInLastSixMonths: 'api/admin/v1/charts/deals/promoted-in-last-six-months',
  },

  MEDIA_GET_PRESIGNED_URL: '',
  MEDIA_STORE: '',
  MEDIA_DELETE: 'api/admin/v1/media/{id}',

  SELECT: {
    regions: 'api/admin/v1/select/places/regions',
    countries: 'api/admin/v1/select/places/countries',
    provinces: 'api/admin/v1/select/places/provinces',
    callCenterOperators: 'api/admin/v1/select/call-center-operators',
    campaigns: 'api/admin/v1/select/campaigns',
    courses: 'api/admin/v1/select/courses',
  },

  EMAIL_SEND: 'api/admin/v1/emails/email-templates/{emailTemplateId}/send',

  /** EMAIL ARCHIVE **/
  EMAIL_ARCHIVE_DELETE: 'api/admin/v1/email-archives/{id}',
  EMAIL_ARCHIVE_UPDATE: 'api/admin/v1/email-archives/{id}',

  /** **/
  EMAIL_TEMPLATES_INDEX: 'api/admin/v1/email-templates',
  EMAIL_TEMPLATE_PARSE: 'api/admin/v1/email-templates',

  EMAIL_TEMPLATES_INDEX_V2: 'api/admin/v2/email-templates',
  EMAIL_TEMPLATES_UPDATE_V2: 'api/admin/v2/email-templates/{id}',
  EMAIL_TEMPLATES_PRESIGNED_URL_V2: 'api/admin/v2/email-templates/{id}/get-document-presigned-url',
  EMAIL_GET_BODY_V2: 'api/admin/v2/email-templates/{id}/target/{targetId}',

  PLACE_INDEX: 'api/admin/v1/places',

  REPORTS_SAVE: 'api/admin/v1/reports',
  REPORTS_SHOW: 'api/admin/v1/reports/{id}',
  REPORTS_DELETE: 'api/admin/v1/reports/{id}',
  REPORTS_EXPORT: 'api/admin/v1/reports/{id}/export',
  REPORTS_BALANCE: 'api/admin/v1/reports/balances/{year}/{month}',

  STUDENT_SHOW: 'api/admin/v1/didactics/students/{id}',
  STUDENT_DELETE: 'api/admin/v1/didactics/students/{id}',
  STUDENT_RESTORE: 'api/admin/v1/didactics/students/{id}/restore',
  STUDENT_SEARCH: 'api/admin/v1/didactics/students/search',
  STUDENT_SHOW_WAITING: 'api/admin/v1/didactics/students/{id}/waiting',
  STUDENT_UPDATE_WAITING: 'api/admin/v1/didactics/students/{id}/waiting',
  STUDENT_UPDATE_ARCHIVE: 'api/admin/v1/didactics/students/{id}/archive',
  // STUDENT_UPDATE_PRESENCES: 'api/admin/v1/didactics/students/{id}/presences',
  STUDENT_UPDATE_SCHEDULE_PREFERENCES: 'api/admin/v1/didactics/students/{id}/schedule-preferences',
  STUDENT_INDEX_BY_REGISTRY: 'api/admin/v1/didactics/students/registries/{id}',
  // STUDENT_INDEX_BY_PENDING_CLASSROOM: 'api/admin/v1/didactics/students/classrooms/{classroomId}/pending',
  // STUDENT_INDEX_BY_ON_GOING_CLASSROOM: 'api/admin/v1/didactics/students/classrooms/{classroomId}/on-going',
  // STUDENT_INDEX_BY_DEAL: 'api/admin/v1/didactics/students/deals/{id}',
  GET_STUDENT_EVENT_LOG: 'api/admin/v1/didactics/students/{id}/event-logs',
  STUDENT_GENERATE: 'api/admin/v1/didactics/students/registries/{registryId}/courses/{courseId}/generate-student',
  STUDENT_WAITING_ATTACH_CLASSROOM: 'api/admin/v1/didactics/students/{id}/waiting/classrooms/{classroomId}/attach',
  STUDENT_WAITING_DETACH_CLASSROOM: 'api/admin/v1/didactics/students/{id}/waiting/classrooms/{classroomId}/detach',
  STUDENT_ON_GOING_UPDATE_ENABLED_CERTIFICATIONS: 'api/admin/v1/didactics/students/{id}/on-going/enabled-certifications',
  STUDENT_TOGGLE_ACCOUNTING_APPROVAL: 'api/admin/v1/didactics/students/{id}/toggle-accounting-approval',
  STUDENT_HANDLE_CLASSROOM_LINK: 'api/admin/v1/didactics/students/{id}/classrooms/{classroomId}/',
  STUDENT_CHANGE_COURSE: 'api/admin/v1/didactics/students/{id}/courses/{courseId}/change',
  STUDENT_ROLLBACK_TO_PENDING_STATUS: 'api/admin/v1/didactics/students/{id}/rollback-to-pending-status',

  CLASSROOM_STORE: 'api/admin/v1/didactics/classrooms',
  CLASSROOM_STORE_PENDING: 'api/admin/v1/didactics/classrooms/courses/{courseId}',
  CLASSROOM_UPDATE_PENDING: 'api/admin/v1/didactics/classrooms/{id}/pending',
  CLASSROOM_DELETE_PENDING: 'api/admin/v1/didactics/classrooms/{id}/pending',
  CLASSROOM_SYNC_LESSONS_PENDING: 'api/admin/v1/didactics/classrooms/{id}/pending/sync-lessons',
  CLASSROOM_SYNC_LESSONS_ON_GOING: 'api/admin/v1/didactics/classrooms/{id}/on-going/sync-lessons',
  CLASSROOM_SYNC_TUTORS: 'api/admin/v1/didactics/classrooms/{id}/sync-tutors',
  CLASSROOM_UPDATE_ON_GOING: 'api/admin/v1/didactics/classrooms/{id}/on-going',
  CLASSROOM_UPDATE_ON_GOING_LESSON: 'api/admin/v1/didactics/classrooms/{id}/on-going/lessons/{lessonId}',
  CLASSROOM_UPDATE_ON_GOING_PROGRAM: 'api/admin/v1/didactics/classrooms/{id}/on-going/program',
  CLASSROOM_DELETE_ON_GOING_LESSON: 'api/admin/v1/didactics/classrooms/{id}/on-going/lessons/{lessonId}',
  CLASSROOM_SHOW_WAITING: 'api/admin/v1/didactics/classrooms/{id}/waiting',
  CLASSROOM_SHOW_ON_GOING: 'api/admin/v1/didactics/classrooms/{id}/on-going',
  CLASSROOM_INDEX: 'api/admin/v1/didactics/classrooms',
  CLASSROOM_EXPORT_DATA: 'api/admin/v1/didactics/classrooms/{id}/export/data',
  CLASSROOM_EXPORT_STUDENTS: 'api/admin/v1/didactics/classrooms/{id}/export/students',
  CLASSROOM_ON_BOARDING_STUDENTS: 'api/admin/v1/didactics/classrooms/{id}/on-boarding-students',
  CLASSROOM_TOGGLE_READY_STATUS: 'api/admin/v1/didactics/classrooms/{id}/toggle-ready-status',
  CLASSROOM_UPDATE_PENDING_CERTIFICATE_LESSON: 'api/admin/v1/didactics/classrooms/{id}/pending/certificate-lesson',
  CLASSROOM_ACTIVATE: 'api/admin/v1/didactics/classrooms/{id}/activate',
  CLASSROOM_STORE_ON_GOING_RECOVERY_LESSON: 'api/admin/v1/didactics/classrooms/{id}/on-going/recovery-lesson',
  CLASSROOM_STORE_ON_GOING_LESSON: 'api/admin/v1/didactics/classrooms/{id}/on-going/lessons',
  CLASSROOM_UPDATE_ON_GOING_CERTIFICATE_PRESENCES: 'api/admin/v1/didactics/classrooms/{id}/on-going/certificate-presences',
  CLASSROOM_UPDATE_ON_GOING_CERTIFICATE_STUDENTS: 'api/admin/v1/didactics/classrooms/{id}/on-going/certificate-students',
  CLASSROOM_SYNC_CERTIFICATES_PENDING: 'api/admin/v1/didactics/classrooms/{id}/pending/sync-certificates',
  CLASSROOM_CLOSE_ON_GOING: 'api/admin/v1/didactics/classrooms/{id}/on-going/close',
  CLASSROOM_INTERRUPT_ON_GOING: 'api/admin/v1/didactics/classrooms/{id}/on-going/interrupt',
  CLASSROOM_REACTIVATE_CLOSED: 'api/admin/v1/didactics/classrooms/{id}/closed/reactivate',
  CLASSROOM_REACTIVATE_INTERRUPTED: 'api/admin/v1/didactics/classrooms/{id}/interrupted/reactivate',

  LESSON_SHOW: 'api/admin/v1/didactics/lessons/{id}',
  // LESSON_UPDATE_PRESENCES: 'api/admin/v1/didactics/lessons/{id}/presences',

  /** COMMISSIONS **/
  COMMISSIONS_CHECK_GENERATION_PERMISSION: 'api/admin/v1/commissions/check-generation-permissions/{year}/{month}',
  COMMISSIONS_GENERATE: 'api/admin/v1/commissions/generate/{year}/{month}',
  COMMISSIONS_DELETE_BY_MONTH: 'api/admin/v1/commissions/by-month/{year}/{month}',
  COMMISSIONS_STORE: 'api/admin/v1/commissions',
  COMMISSIONS_UPDATE: 'api/admin/v1/commissions/{id}',
  COMMISSIONS_DELETE: 'api/admin/v1/commissions/{id}',

  SALE_THRESHOLDS_STORE: '/api/admin/v1/commissions/sale_thresholds',
  SALE_THRESHOLDS_UPDATE: '/api/admin/v1/commissions/sale_thresholds/{id}',
  SALE_THRESHOLDS_DELETE: '/api/admin/v1/commissions/sale_thresholds/{id}',

  SALE_MANAGER_COMMISSIONS_STORE: '/api/admin/v1/commissions/sale_manager_commissions',
  SALE_MANAGER_COMMISSIONS_UPDATE: '/api/admin/v1/commissions/sale_manager_commissions/{id}',
  SALE_MANAGER_COMMISSIONS_DELETE: '/api/admin/v1/commissions/sale_manager_commissions/{id}',

  DASHBOARD_DEALS_STATUS: 'api/admin/v1/dashboard/deals-status',
  DASHBOARD_DEALS_STATUS_BY_LAST_DEAL_DATE: 'api/admin/v1/dashboard/deals-status-by-last-deal-date',
  DASHBOARD_DEALS_WITH_DATE_OUT: 'api/admin/v1/dashboard/deals-with-date-out',
  DASHBOARD_DEALS_STATS: 'api/admin/v1/dashboard/deals-stats',
  DASHBOARD_LEADS_STATUS: 'api/admin/v1/dashboard/leads-status',
  DASHBOARD_STUDENTS_STATS: 'api/admin/v1/dashboard/students-stats',
  DASHBOARD_CALL_CENTER_LEADS_WITH_DEADLINES: 'api/admin/v1/dashboard/call-center-leads-with-deadlines',

  LOCATION_INDEX: 'api/admin/v1/didactics/locations',
  LOCATION_STORE: 'api/admin/v1/didactics/locations',
  LOCATION_UPDATE: 'api/admin/v1/didactics/locations/{id}',

  NOTE_STORE: 'api/admin/v1/notes',
  NOTE_UPDATE: 'api/admin/v1/notes/{id}',

  SETTINGS_ZOHO: 'api/admin/v1/zoho/secrets',

  CLIENT_STORE_FROM_PROVIDER: 'api/admin/v1/clients/providers/{providerId}',
  CLIENT_TOGGLE_REVOKED_VALUE: 'api/admin/v1/clients/{id}/toggle-revoked-value',
  CLIENT_REFRESH_SECRET: 'api/admin/v1/clients/{id}/refresh-secret',

};

export {
  endpoints
}
