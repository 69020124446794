import Vue from "vue";
import _ from "lodash";
import ClassroomPending from "@/store/current/resources/classroomPending";
import StudentPending from "@/store/current/resources/studentPending";
import ClassroomOnGoing from "@/store/current/resources/classroomOnGoing";
import StudentArchive from "@/store/current/resources/studentArchive";

export const mutations = {
  setClassroomPending(state, data) {
    state.classroomPending = data;
  },

  setPartialClassroomPending(state, data) {
    Vue.set(state.classroomPending, data.key, data.value);
  },

  setClassroomOnGoing(state, data) {
    state.classroomOnGoing = data;
  },

  setPartialClassroomOnGoing(state, data) {
    Vue.set(state.classroomOnGoing, data.key, data.value);
  },

  setClassroomPendingTutors(state, data) {
    Vue.set(state.classroomPending, 'tutors', data);
  },

  setStudentPending(state, data) {
    state.studentPending = data;
  },

  setPartialStudentPending(state, data) {
    Vue.set(state.studentPending, data.key, data.value);
  },

  setStudentPendingClassrooms(state, data) {
    Vue.set(state.studentPending, 'classrooms', data);
  },

  setStudentPendingStatus(state, data) {
    Vue.set(state.studentPending, 'status', data);
  },

  setStudentPendingRecapData(state, data) {
    Vue.set(state.studentPending, 'first_contact', data?.first_contact);
    Vue.set(state.studentPending, 'second_contact', data?.second_contact);
    Vue.set(state.studentPending, 'status', data?.status);
    Vue.set(state.studentPending, 'starting_preference', data?.starting_preference);
    Vue.set(state.studentPending, 'location_id', data?.location_id);
  },

  setStudentArchive(state, data) {
    state.studentArchive = data;
  },

  setPartialStudentArchive(state, data) {
    Vue.set(state.studentArchive, data.key, data.value);
  },

  resetState(state) {
    state.classroomPending = _.cloneDeep(ClassroomPending);
    state.classroomOnGoing = _.cloneDeep(ClassroomOnGoing);
    state.studentPending = _.cloneDeep(StudentPending);
    state.studentArchive = _.cloneDeep(StudentArchive);
  }
}
