import Vue from "vue";
import _ from "lodash";

export const mutations = {

  setSettings(state, data) {
    state.settings = data;
  },

  setCountries(state, data) {
    state.countries = data;
  },

  setRegions(state, data) {
    state.regions = data;
  },

  setProvinces(state, data) {
    state.provinces = data;
  },

  setHelper(state, data) {
    state.helper = data;
  },

  setUsers(state, data) {
    state.users = data;
  },

  setCampaigns(state, data) {
    state.campaigns = _.keyBy(data, (item) => {
      return item.id;
    });
  },

  updateCampaigns(state, data) {
    Vue.set(state.campaigns, data.id, data);
  },

  updateCourses(state, data) {
    Vue.set(state.courses, data.id, data);
  },


  updateUsers(state, data) {
    Vue.set(state.users, data.id, data);
  },

  setCourses(state, data) {
    state.courses = _.keyBy(data, (item) => {
      return item.id;
    });
  },

  setProviders(state, data) {
    state.providers = _.keyBy(data, (item) => {
      return item.slug;
    });
  },

  setBroadcastEvent(state, data) {
    state.broadcastEvent = data;
  },

  setSettingsByName(state, data) {
    Vue.set(state.settings, data.name, data.value);
  },

  setDatatableFilter(state, data) {
    Vue.set(state.datatables, data.name, data.data);
  },

  setDatatableData(state, data) {
    Vue.set(state.datatablesData, data.name, data.data);
  },

  setDatatableOptions(state, data) {
    if (!state.datatableOptions[data.name]) {
      Vue.set(state.datatableOptions, data.name, {})
    }
    Vue.set(state.datatableOptions[data.name], data.select, data.options);
  },

  setSubjects(state, data) {
    state.subjects = data;
  },

  setLocations(state, data) {
    state.locations = data;
  },

  setCourseModules(state, data) {
    state.courseModules = data;
  },

  setEmailTemplates(state, data) {
    state.emailTemplates = _.keyBy(data, item => item.id);
  },

  resetState(state) {
    state.settings = {};
    state.datatables = {};
    state.datatableOptions = {};
    state.datatablesData = {};
    state.users = [];
    state.campaigns = [];
    state.courses = [];
    state.helper = null;
    state.broadcastEvent = {};
    state.emailTemplates = {};
  }
}
