import {actions} from './actions'
import {getters} from './getters'
import {mutations} from './mutations'
import ClassroomPending from "@/store/current/resources/classroomPending";
import _ from "lodash";
import StudentPending from "@/store/current/resources/studentPending";
import ClassroomOnGoing from "@/store/current/resources/classroomOnGoing";
import StudentArchive from "@/store/current/resources/studentArchive";

const state = {
  classroomPending: _.cloneDeep(ClassroomPending),
  classroomOnGoing: _.cloneDeep(ClassroomOnGoing),
  studentPending: _.cloneDeep(StudentPending),
  studentArchive: _.cloneDeep(StudentArchive)
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
