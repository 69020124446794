import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const modules = {};

const requireModule = require.context('@/store', true, /[a-zA-Z0-9]+\/index\.js$/);

requireModule.keys().forEach(fileName => {
    const moduleName = fileName.replace(/(\.\/|\/index\.js)/g, '');
    modules[moduleName.toLowerCase()] = requireModule(fileName).default
});

const store = new Vuex.Store({
    modules,
    plugins: [createPersistedState({
        key: process.env.VUE_APP_STORE_KEY + process.env.NODE_ENV
    })]
});

export default store
