import {permissionService} from "@/util/permission-service";

const CallCenterMenuItems = {
  roles: [
    permissionService.CALL_CENTER_OPERATOR_ROLE
  ],
  link: {
    name: 'sidebar.call_center',
    icon: 'headset'
  },
  children: [

    {
      roles: [
        permissionService.CALL_CENTER_OPERATOR_ROLE
      ],
      link: {
        name: 'sidebar.call_center_operator',
        route: 'operator.handle_lead'
      }
    },
    {
      roles: [
        permissionService.CALL_CENTER_OPERATOR_ROLE
      ],
      link: {
        name: 'sidebar.call_center_operator_search_registry',
        route: 'call-center.operator.search-registry'
      }
    }
  ]
};

export default CallCenterMenuItems;
