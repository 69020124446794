import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const SettingRoutes =
  {
    path: '/settings',
    name: 'settings',
    component: DashboardLayout,
    redirect: '/settings',
    children: [
      {
        path: 'call-center',
        name: 'settings.call_center',
        components: {default: () => import('@/pages/Settings/CallCenterPage')},
        meta: {
          requiresAuth: true,
          title: 'call_center_settings',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'sales',
        name: 'settings.sales',
        components: {default: () => import('@/pages/Settings/SalesPage')},
        meta: {
          requiresAuth: true,
          title: 'sales_settings',
          roles: [permissionService.SALES_MANAGER_ROLE]
        },
      },
      {
        path: 'didactics',
        name: 'settings.didactics',
        components: {default: () => import('@/pages/Settings/DidacticsPage')},
        meta: {
          requiresAuth: true,
          title: 'didactics_settings',
          roles: [permissionService.TEACHING_MANAGER_ROLE]
        },
      },
      {
        path: 'email-templates',
        name: 'settings.email_templates',
        components: {default: () => import('@/pages/Settings/EmailTemplatePage')},
        meta: {
          requiresAuth: true,
          title: 'email_templates',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'zoho',
        name: 'settings.zoho',
        components: {default: () => import('@/pages/Settings/ZohoPage.vue')},
        meta: {
          requiresAuth: true,
          title: 'zoho',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
    ]
  };

export default SettingRoutes;
