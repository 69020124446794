import {permissionService} from "@/util/permission-service";

const RegistryMenuItems = {
    roles: [
      permissionService.CALL_CENTER_MANAGER_ROLE,
      permissionService.ACCOUNTANT_ROLE,
      permissionService.TEACHING_MANAGER_ROLE,
      permissionService.SALES_ROLE,
    ],
    link: {
      name: 'sidebar.registry',
      icon: 'users'
    },
    children: [
      {
        roles: [
          permissionService.CALL_CENTER_MANAGER_ROLE,
          permissionService.SALES_ROLE,
        ],
        link: {
          name: 'sidebar.new',
          route: 'registries.wizard'
        }
      },
      {
        roles: [
          permissionService.ADMIN_ROLE
        ],
        link: {
          name: 'sidebar.import',
          route: 'providers.import.index'
        }
      },
      {
        roles: [
          permissionService.CALL_CENTER_MANAGER_ROLE,
          permissionService.ACCOUNTANT_ROLE,
          permissionService.TEACHING_MANAGER_ROLE,
        ],
        link: {
          name: 'sidebar.list',
          route: 'registries.index'
        }
      },
      {
        roles: [
          permissionService.ADMIN_ROLE
        ],
        link: {
          name: 'sidebar.provider_import_data_external',
          route: 'provider-import-data.external.index'
        }
      },

    ]
  };

export default RegistryMenuItems;
