import DashboardLayout from '@/pages/Layout/DashboardLayout.vue';
import {permissionService} from "@/util/permission-service";

const RegistryRoutes =
  {
    path: '/registries',
    name: 'registries.main',
    component: DashboardLayout,
    redirect: '/registries',
    children: [
      {
        path: '/',
        name: 'registries.index',
        components: { default: () => import('@/pages/Registries/IndexPage') },
        meta: {
          requiresAuth: true,
          title: 'registries',
          roles: [
            permissionService.CALL_CENTER_MANAGER_ROLE,
            permissionService.ACCOUNTANT_ROLE,
            permissionService.TEACHING_MANAGER_ROLE
          ]
        },
      },

      {
        path: 'wizard',
        name: 'registries.wizard',
        components: { default: () => import('@/pages/Registries/WizardPage') },
        meta: {
          requiresAuth: true,
          title: 'registries_wizard',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE, permissionService.SALES_ROLE,]
        },
      },
      {
        path: 'providers',
        name: 'registries.providers',
        components: { default: () => import('@/pages/Registries/ProvidersPage') },
        meta: {
          requiresAuth: true,
          title: 'registries_providers',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: 'import/wizard',
        name: 'registries.import.wizard',
        components: { default: () => import('@/pages/Registries/ImportWizardPage') },
        meta: {
          requiresAuth: true,
          title: 'registries_import_wizard',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: ':id',
        name: 'registries.show',
        components: {default: () => import('@/pages/Registries/ShowPage')},
        meta: {
          requiresAuth: true,
          title: 'registry',
          roles: [
            permissionService.CALL_CENTER_MANAGER_ROLE,
            permissionService.ACCOUNTANT_ROLE,
            permissionService.TEACHING_MANAGER_ROLE,
            permissionService.SALES_ROLE,
          ]
        },
      },
      {
        path: 'providers/import/:id',
        name: 'providers.import.show',
        components: { default: () => import('@/pages/Registries/ProviderImportDetailShowPage') },
        meta: {
          requiresAuth: true,
          title: 'provider',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: 'providers/import',
        name: 'providers.import.index',
        components: { default: () => import('@/pages/Registries/ProviderImportIndexPage') },
        meta: {
          requiresAuth: true,
          title: 'registries_import',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: 'provider-import-data/external',
        name: 'provider-import-data.external.index',
        components: { default: () => import('@/pages/Registries/ProviderImportDataExternalIndexPage') },
        meta: {
          requiresAuth: true,
          title: 'provider_import_data_external',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
    ]
  };

export default RegistryRoutes;
