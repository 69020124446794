import {permissionService} from "@/util/permission-service";
import _ from "lodash";

export const getters = {
  settings: state => state.settings,
  countries: state => state.countries,
  regions: state => state.regions,
  provinces: state => state.provinces,
  users: state =>  state.users,
  campaigns: state => state.campaigns,
  allCourses: state =>state.courses,
  courses: state => _.filter(state.courses, course => course.type === 'course'),
  certificates: state => _.filter(state.courses, course => course.type === 'certificate'),
  workshops: state => _.filter(state.courses, course => course.type === 'workshop'),
  providers: state => state.providers,
  helper: state => state.helper,
  broadcastEvent: state => state.broadcastEvent,
  subjects: state => state.subjects,
  locations: state => state.locations,
  courseModules: state => state.courseModules,
  emailTemplates: state => state.emailTemplates,

  /** DATATABLES **/
  datatableQuery: state => (table) => {
    return state?.datatables[table]?.query || '';
  },
  datatableDirection: state => (table) => {
    return state?.datatables[table]?.direction || '';
  },
  datatableOrderBy: state => (table) => {
    return state?.datatables[table]?.orderBy || '';
  },
  datatableCurrentPage: state => (table) => {
    return state?.datatables[table]?.currentPage || 1;
  },
  datatableFilters: state => (table) => {
    return state?.datatables[table]?.filters || {};
  },
  datatableOptions: state => (table) => {
    return state?.datatableOptions?.[table] || [];
  },
  datatableData: state => (table) => {
    return state?.datatablesData[table] || {};
  },

  sales: state => {
    return _.filter(state.users, user => {
      return _.find(user.roles, role => role.name === permissionService.SALES_ROLE)
    });
  },

  operators: state => {
    return _.filter(state.users, user => {
      return _.find(user.roles, role => {
        return (
          role.name === permissionService.CALL_CENTER_OPERATOR_ROLE
          || role.name === permissionService.CALL_CENTER_MANAGER_ROLE
        );
      })
    });
  },

  tutors: state => {
    return _.sortBy(
      _.filter(state.users, user => {
        return _.find(user.roles, role => {
          return role.name === permissionService.TUTOR_ROLE;
        })
      }),
      tutor => tutor.lastname + ' ' + tutor.firstname
    );
  },
}
