<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  methods: {
    initializeLayout() {
      const docClasses = document.body.classList;
      if (this.themeMode === 'light') {
        docClasses.add('white-content');
      } else {
        docClasses.remove('white-content');
      }
    }
  },
  computed: {
    ...mapGetters({
      themeMode: 'auth/themeMode',
    }),
  },
  beforeMount() {

  },
  mounted() {
    this.initializeLayout();


  }
};
</script>
