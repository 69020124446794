import Vue from "vue";
import {endpoints} from "@/routes/endpoints";

export const actions = {
  downloadFile({commit}, data) {
    return new Promise((resolve, reject) => {
      let dataRequest = {
        method: data.method || 'get',
        url: data.url,
        responseType: 'arraybuffer',
      };

      if (dataRequest.method === 'post' && data.data) {
        dataRequest.data = data.data;
      }

      Vue.prototype.$api(dataRequest)
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', data.filename);
          document.body.appendChild(link);
          link.click();
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        })
    });
  },

  getSettings({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.CONFIG_SETTINGS_INDEX)
        .then((resp) => {
          commit('setSettings', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },

  updateSettingsByName({commit}, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.post(endpoints.CONFIG_SETTINGS_POST, data)
        .then((resp) => {
          commit('setSettingsByName', resp.data.data);
          resolve(resp.data.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },

  updateSettings({commit}, data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.put(endpoints.CONFIG_SETTINGS_PUT, data)
        .then((resp) => {
          commit('setSettings', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },

  getPlaces({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.PLACE_INDEX)
        .then((resp) => {
          commit('setCountries', resp.data?.countries);
          commit('setRegions', resp.data?.regions);
          commit('setProvinces', resp.data?.provinces);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getUsers({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.USER_INDEX)
        .then((resp) => {
          commit('setUsers', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getCampaigns({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.CAMPAIGN_INDEX)
        .then((resp) => {
          commit('setCampaigns', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getCourses({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.COURSE_INDEX)
        .then((resp) => {
          commit('setCourses', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getProviders({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.PROVIDER_INDEX)
        .then((resp) => {
          commit('setProviders', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getSubjects({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.COURSE_SUBJECT_INDEX)
        .then((resp) => {
          commit('setSubjects', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getEmailTemplates({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.EMAIL_TEMPLATES_INDEX_V2)
        .then((resp) => {
          commit('setEmailTemplates', resp?.data?.data || []);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getLocations({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.LOCATION_INDEX)
        .then((resp) => {
          commit('setLocations', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },

  getCourseModules({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.COURSE_MODULE_INDEX)
        .then((resp) => {
          commit('setCourseModules', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
}
