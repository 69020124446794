
export const CampaignRules = {
  deadline : 0,
  statuses : [],
  courses: []
};


const Campaign = {
  id: null,
  name: '',
  priority: 3,
  description: '',
  auto: false,
  rules: CampaignRules,
};

export default Campaign

