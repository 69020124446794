import store from '@/store'
import _ from 'lodash';

const permissionService = {

  ADMIN_ROLE: 'admin',
  CALL_CENTER_MANAGER_ROLE: 'call-center-manager',
  CALL_CENTER_OPERATOR_ROLE: 'call-center-operator',
  SALES_MANAGER_ROLE: 'sales-manager',
  SALES_ROLE: 'sales',
  TUTOR_ROLE: 'tutor',
  TEACHING_MANAGER_ROLE: 'teaching-manager',
  ACCOUNTANT_ROLE: 'accountant',

  isAllowed(roles) {
    let userRoles = [];

    if (!store.getters['auth/isLogged']) {
      return false;
    }

    if (_.isEmpty(roles)) {
      return true;
    }

    _.each(store.getters['auth/userRoles'], (role) => {
      userRoles = _.uniq(_.concat(userRoles, this.getFullUserRoles(role.name)))
    });

    return !!_.intersection(userRoles, roles).length;

  },

  getFullUserRoles(role) {
    switch(role) {
      case this.ADMIN_ROLE:
        return [
          this.ADMIN_ROLE,
          this.CALL_CENTER_MANAGER_ROLE,
          this.CALL_CENTER_OPERATOR_ROLE,
          this.SALES_MANAGER_ROLE,
          this.SALES_ROLE,
          this.TUTOR_ROLE,
          this.TEACHING_MANAGER_ROLE,
          this.ACCOUNTANT_ROLE,
        ]
      case this.CALL_CENTER_MANAGER_ROLE:
        return [
          this.CALL_CENTER_MANAGER_ROLE,
          this.CALL_CENTER_OPERATOR_ROLE,
        ]
      case this.SALES_MANAGER_ROLE:
        return [
          this.SALES_MANAGER_ROLE,
          this.SALES_ROLE,
        ]
      default:
        return [role]
    }
  }
};

export {
  permissionService
}
