<template>
  <component
    :is="tag"
    :type="tag === 'button' ? nativeType : ''"
    :disabled="disabled || loading"
    @click="handleClick"
    class="btn"
    :class="[
      { 'btn-round': round },
      { 'btn-block': block },
      { 'btn-wd': wide },
      { 'btn-icon btn-fab': icon },
      { [`btn-${buttonType}`]: buttonType },
      { [`btn-${size}`]: size },
      { 'btn-simple': simple },
      { 'btn-link': link },
      { disabled: disabled && tag !== 'button' }
    ]"
  >
    <slot name="loading">
      <i v-if="loading" class="fas fa-spinner fa-spin"></i>
    </slot>
    <slot></slot>
  </component>
</template>
<script>
import {mapGetters} from "vuex";
import { MessageBox } from "element-ui";

export default {
  name: 'base-button',
  props: {
    tag: {
      type: String,
      default: 'button',
      description: 'Button html tag'
    },
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    wide: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: null,
      description: 'Button type (primary|secondary|danger etc)'
    },
    nativeType: {
      type: String,
      default: 'button',
      description: 'Button native type (e.g button, input etc)'
    },
    size: {
      type: String,
      default: '',
      description: 'Button size (sm|lg)'
    },
    simple: {
      type: Boolean,
      description: 'Whether button is simple (outlined)'
    },
    link: {
      type: Boolean,
      description: 'Whether button is a link (no borders or background)'
    },
    confirmRequired: {
      type: Boolean,
      default: false,
      description: 'Whether button action has to be confirmed'
    },
    confirmTitle: {
      type: String,
      default: '',
      description: 'Title of confirmation modal'
    },
    confirmText: {
      type: String,
      default: '',
      description: 'Body of confirmation modal'
    },
    confirmYes: {
      type: String,
      default: '',
      description: 'Text to be displayed in modal Yes button'
    },
    confirmNo: {
      type: String,
      default: '',
      description: 'Text to be displayed in modal Yes button'
    },
    selected: {
      type: Boolean,
      default: false,
      description: 'Whether button is selected or not'
    }
  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
      themeMode: 'auth/themeMode',
    }),
    buttonType: function () {
      if (this.type) {
        return this.type;
      }

      if (this.selected) {
        return this.themeMode === 'light' ? 'black' : ''
      }

      switch (this.themeColor) {
        case 'primary':
          return 'primary';
        case 'vue':
          return 'success';
        case 'green':
          return 'success';
        case 'red':
          return 'danger';
        case 'orange':
          return 'warning';
        case 'blue':
          return 'info';
        default:
          return 'primary';
      }
    }
  },
  methods: {
    handleClick(evt) {
      if (this.confirmRequired) {
        MessageBox({
          title: this.confirmTitle || this.$t('confirm.confirm_title'),
          message: this.confirmText || this.$t('confirm.confirm_text'),
          confirmButtonText: this.confirmYes || this.$t('common.yes'),
          cancelButtonText: this.confirmNo || this.$t('common.no'),
          showCancelButton: true,
          type: 'warning'
        })
          .then(() => {
            this.$emit('click', evt);
          })
          .catch(() => {
          });
      } else {
        this.$emit('click', evt);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /deep/ i {
    padding: 0 3px;
  }
}
</style>
