import Vue from "vue";
import {endpoints} from "@/routes/endpoints";
import _ from "lodash";

export const actions = {

  logout({dispatch}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.post(endpoints.USER_LOGOUT)
        .then((resp) => {
          dispatch('resetAllStates');
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });

  },

  resetAllStates({commit, rootState}) {
    _.each(rootState, (state, moduleName) => {
      commit(moduleName + '/resetState', null, {root: true});
    })
    commit('resetState');
  },

  getRoles({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.ROLE_INDEX)
        .then((resp) => {
          commit('setRoles', resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },

  getCurrentUser({commit}) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.get(endpoints.USER_CURRENT)
        .then((resp) => {
          commit('setCurrentUser', resp.data.data);
          resolve(resp.data.data);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
}
