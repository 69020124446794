import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const LeadRoutes =
  {
    path: '/emails',
    name: 'emails',
    component: DashboardLayout,
    redirect: '/emails',
    children: [
      {
        path: 'call-center-emails',
        name: 'leads.call_center_emails',
        components: {default: () => import('@/pages/Email/IndexCallCenterEmailPage')},
        meta: {
          requiresAuth: true,
          title: 'call_center_emails',
          roles: [permissionService.CALL_CENTER_MANAGER_ROLE]
        },
      },
      {
        path: 'email-archive',
        name: 'email-archive.index',
        components: { default: () => import('@/pages/Email/IndexEmailArchivePage') },
        meta: {
          requiresAuth: true,
          title: 'email_archive',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
      {
        path: 'email-scheduled',
        name: 'email-scheduled.index',
        components: { default: () => import('@/pages/Email/IndexEmailScheduledPage') },
        meta: {
          requiresAuth: true,
          title: 'email_scheduled',
          roles: [permissionService.ADMIN_ROLE]
        },
      },
    ]
  };

export default LeadRoutes;
