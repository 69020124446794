const Provider = {
  id: null,
  name: '',
  enabled: true,
  slug: '',
  record_price: null,
  campaign_id: null,
  xls_mapping: [],
};

export default Provider;
