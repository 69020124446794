import DashboardLayout from "@/pages/Layout/DashboardLayout";
import {permissionService} from "@/util/permission-service";

const PostSellingRoutes =
  {
    path: '/post-selling',
    name: 'post-selling.main',
    component: DashboardLayout,
    redirect: '/post-selling/archive',
    children: [
      {
        path: 'archive',
        name: 'post_selling.index',
        components: {default: () => import('@/pages/PostSelling/IndexPostSellingArchivePage.vue')},
        meta: {
          requiresAuth: true,
          title: 'post_selling_archive',
          roles: [permissionService.ACCOUNTANT_ROLE]
        },
      },
      {
        path: 'post-selling/deals/:id',
        name: 'post_selling.show',
        components: {default: () => import('@/pages/PostSelling/ShowPostSellingPage.vue')},
        meta: {
          requiresAuth: true,
          title: 'deals_to_approve',
          roles: [permissionService.ACCOUNTANT_ROLE]
        },
      },
      {
        path: 'payments',
        name: 'deals.payments',
        components: {default: () => import('@/pages/PostSelling/IndexPaymentPage.vue')},
        meta: {
          requiresAuth: true,
          title: 'payments',
          roles: [permissionService.ACCOUNTANT_ROLE]
        },
      },
      {
        path: 'financings',
        name: 'post-selling.financings',
        components: {default: () => import('@/pages/PostSelling/IndexFinancingPage.vue')},
        meta: {
          requiresAuth: true,
          title: 'financing',
          roles: [permissionService.ACCOUNTANT_ROLE]
        },
      },
      {
        path: 'debt_collections',
        name: 'post-selling.debt_collections.show',
        components: {default: () => import('@/pages/PostSelling/IndexDebtCollectionPage.vue')},
        meta: {
          requiresAuth: true,
          title: 'debt_collection',
          roles: [permissionService.ACCOUNTANT_ROLE]
        },
      },
    ]
  };

export default PostSellingRoutes;
