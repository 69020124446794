import Vue from 'vue'
import Moment from 'moment';

Vue.filter('euro', function (value) {
  if (isNaN(value) || (!value && value !== 0)) {
    return 'N.D.'
  }

  return parseFloat(value).toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });
});

Vue.filter('number', function (value, fraction) {

  if (isNaN(value) || !value) {
    value = 0
  }

  return Number(value).toLocaleString('it-IT', {
    style: 'decimal',
    minimumFractionDigits: fraction || 0,
    maximumFractionDigits: fraction || 0,
  });
});

Vue.filter('udid', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString();
  return value.padStart(6, '0');
});

Vue.filter('date', function (value, format) {

  if (!value) {
    return '';
  }

  if (!format) {
    format = 'DD/MM/YYYY';
  }

  let date = Moment(value, "YYYY-MM-DD");

  return date.isValid() ? date.format(format) : value;
});

Vue.filter('day', function (value, format) {

  let date = Moment(value, "YYYY-MM-DD");
  let days;
  switch (format) {
    case 'short':
      days = {
        'Su': 'dom',
        'Mo': 'lun',
        'Tu': 'mar',
        'We': 'mer',
        'Th': 'gio',
        'Fr': 'ven',
        'Sa': 'sab'
      }
      break;
    default :
      days = {
        'Su': 'Domenica',
        'Mo': 'Lunedì',
        'Tu': 'Martedì',
        'We': 'Mercoledì',
        'Th': 'Giovedì',
        'Fr': 'Venerdì',
        'Sa': 'Sabato'
      }
      break;
  }

  return date.isValid() ? days[date.format('dd')] : value;
});

Vue.filter('datetime', function (value) {

  let date = Moment(value, "YYYY-MM-DD HH:mm:ss");

  return date.isValid() ? date.format('DD/MM/YYYY HH:mm') : value;
});

Vue.filter('time', function (value) {

  if (value && value.length === 8) {
    return value.slice(0, -3);
  }

  let date = Moment(value, "YYYY-MM-DD HH:mm:ss");

  return date.isValid() ? date.format('HH:mm') : value;

});

Vue.filter('leadStatusColor', function (value) {
  switch (value) {
    case 'free':
      return 'info';
    case 'promoted':
      return 'success';
    case 'processing':
      return 'warning';
    case 'invalid':
    case 'not_interested':
      return 'default';
    default:
      return 'primary'
  }
});

Vue.filter('callCenterLeadStatusColor', function (value) {
  switch (value) {
    case 'free':
      return 'success';
    case 'public':
      return 'info';
    case 'private':
      return 'warning';
    case 'processing':
      return 'danger';
    default:
      return 'primary'
  }
});

Vue.filter('roleColor', function (value) {
  switch (value) {
    case 'admin':
      return 'primary';
    case 'call-center-operator':
    case 'call-center-manager':
      return 'success';
    case 'sales':
    case 'sales-manager':
      return 'warning';
    case 'teaching-manager':
    case 'tutor':
      return 'info';
    case 'accountant':
      return 'danger';
    default:
      return 'primary'
  }
});

Vue.filter('courseStatusColor', function (value) {
  switch (value) {
    case 'certificate':
      return 'success';
    default:
      return 'primary'
  }
});

Vue.filter('dealStatusColor', function (value) {

  switch (value) {

    case 'promoted':
    case 'closed':
      return 'info';

    case 'recall':
      return 'primary';

    case 'processing':
      return 'warning';

    case 'pending':
      return 'danger';

    case 'confirmed':
      return 'success';

    case 'not_valid':
    case 'not_interested':
      return 'default'

    default:
      return 'default'
  }
});

Vue.filter('optional', function (value, nesting) {

  if (!nesting) {
    return value;
  }

  for (let level of nesting.split('.')) {

    if (!value || !value.hasOwnProperty(level)) {
      return '';
    }
    value = value[level];
  }

  return value
});

Vue.filter('classroomTypeColor', function (value) {
  switch (value) {
    case 'course':
      return 'primary';
    case 'certificate':
      return 'danger';
    case 'workshop':
      return 'warning';
    default:
      return 'default'
  }
});

Vue.filter('classroomStatusColor', function (value) {
  switch (value) {
    case 'on_going':
      return 'primary';
    case 'pending':
      return 'info';
    case 'scheduled':
      return 'warning';
    case 'ready_to_go':
      return 'success';
    default:
      return 'default'
  }
});

Vue.filter('lessonStatusColor', function (value) {
  switch (value) {
    case 'completed':
      return 'success';
    case 'cancelled':
      return 'danger';
    case 'postponed':
      return 'info';
    case 'scheduled':
      return 'warning';
    case 'holiday':
      return 'warning';
    default:
      return 'default'
  }
});

Vue.filter('studentStatusColor', function (value) {


  switch (value) {
    case 'pending':
    case 'to_block_for_withdrawal':
    case 'request_retired':
    case 'request_stand_by':
    case 'to_block_for_non_payment':
    case 'to_unblock':
      return 'info';

    case 'joined':
    case 'attending':
      return 'primary';

    case 'stand_by':
    case 'unjustified_absence':
    case 'deleted':
      return 'warning';

    case 'completed':
      return 'success';

    case 'blocked_for_withdrawal':
      return 'danger';

    default:
      return 'default'
  }
});

Vue.filter('balanceReasonColor', function (value) {
  switch (value) {
    case 'appointment_operator_award':
      return 'info';

    case 'deal_contracted_operator_award':
      return 'primary';

    case 'deal_contracted_manager_award':
      return 'success';

    case 'deal_personal_threshold_award':
    case 'deal_group_threshold_award':
      return 'danger';

    case 'deal_enrollment':
    case 'deal_confirmed':
      return 'warning';

    default:
      return 'default'
  }
});

Vue.filter('userCode', function (user) {
  return user?.lastname || user?.username;
});


Vue.filter('padStart', function (value, length) {
  value = value.toString();
  return value.padStart(length, '0');
});


Vue.filter('studentTypeColor', function (value) {
  switch (value) {
    case 'main':
      return 'primary';
    case 'guest':
      return 'warning';
    case 'transferred':
      return 'info';
    default:
      return 'default'
  }
});

Vue.filter('courseTypeColor', function (value) {
  switch (value) {
    case 'workshop':
      return 'primary';

    case 'certificate':
      return 'success';

    case 'course':
    default:
      return 'info';
  }
});

Vue.filter('emailStatusColor', function (value) {
  switch (value) {
    case 'pending':
      return 'warning';

    case 'ready':
      return 'success';

    case 'sent':
    default:
      return 'info';
  }
});
