import {permissionService} from "@/util/permission-service";

const PostSellingMenuItems =
    {
      roles: [
        permissionService.ACCOUNTANT_ROLE,
        permissionService.TEACHING_MANAGER_ROLE,
      ],
      link: {
        name: 'sidebar.post-selling',
        icon: 'list',
        route: 'post-selling.index'
      },
      children: [
        {
          roles: [
            permissionService.SALES_MANAGER_ROLE,
            permissionService.ACCOUNTANT_ROLE,
          ],
          link: {
            name: 'sidebar.payments',
            route: 'deals.payments'
          }
        },
        {
          roles: [
            permissionService.SALES_MANAGER_ROLE,
            permissionService.ACCOUNTANT_ROLE,
          ],
          link: {
            name: 'sidebar.financing',
            route: 'post-selling.financings'
          }
        },
        {
          roles: [
            permissionService.SALES_MANAGER_ROLE,
            permissionService.ACCOUNTANT_ROLE,
          ],
          link: {
            name: 'sidebar.debt_collection',
            route: 'post-selling.debt_collections.show'
          }
        },
        {
          roles: [
            permissionService.SALES_MANAGER_ROLE,
            permissionService.ACCOUNTANT_ROLE,
          ],
          link: {
            name: 'sidebar.list',
            route: 'post_selling.index'
          }
        }
      ]
    };

export default PostSellingMenuItems;
