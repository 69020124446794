export const getters = {
  isLogged: state => !!state.currentUser.id,
  accessToken: state => state.accessToken,
  roles: state => state.roles,
  currentUser: state => state.currentUser,
  userRoles: state => state.currentUser ? state.currentUser.roles : [],
  themeMode: (state) => {
    return (state.currentUser) ? state.currentUser.theme_mode : 'light';
  },
  themeColor: (state) => {
    return (state.currentUser) ? state.currentUser.theme_color : 'blue';
  },
  sidebarStatus: (state) => {
    return (state.currentUser) ? state.currentUser.sidebar_status : 'extended';
  },
  settings: state => state.settings
}
