<template>
  <div>
    <base-nav
      v-model="showMenu"
      class="navbar-absolute top-navbar"
      type="white"
      :transparent="true"
    >
      <div slot="brand" class="navbar-wrapper">
        <div class="navbar-minimize d-inline">
          <sidebar-toggle-button/>
        </div>
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button type="button" class="navbar-toggler" @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <div class="navbar-brand">{{ $t('page.' + routeName) }}</div>
      </div>

      <ul class="navbar-nav ml-auto">
        <base-dropdown
          tag="li"
          menu-on-right
          title-tag="a"
          class="nav-item"
          title-classes="nav-link"
          :menu-classes="`dropdown-navbar ${themeLight ? '' : 'bg-dark'}`"
        >
          <template
            slot="title"
          >
            <div class="d-flex align-items-center ">
              <div class="mr-2">{{ profileName }}</div>
              <div class="photo">
                <img :src="imgUser"/>
              </div>
            </div>
          </template>
          <li>
            <div class="d-flex p-1 justify-content-center align-items-center">
              <div :class="`bg-${item.color} ${item.padding} rounded-circle img-thumbnail mx-1`"
                   v-for="(item, index) in userThemeColors"
                   v-bind:key="index"
                   v-on:click.stop="changeThemeColor(item.color)">
              </div>
            </div>
          </li>
          <div class="dropdown-divider"></div>
          <li>
            <div class="d-flex justify-content-center align-items-center" v-on:click.stop="toggleMode">
              <div class="small mr-2 mb-2 text-center text-body">DARK<br/>MODE</div>
              <base-switch v-model="themeLight"></base-switch>
              <div class="small ml-2 mb-2 text-center text-body">LIGHT<br/>MODE</div>
            </div>
          </li>
          <li>
            <div class="d-flex justify-content-center align-items-center" v-on:click.stop="toggleSidebar">
              <div class="small mr-2 mb-2 text-center text-body">SIDEBAR<br/>EXT</div>
              <base-switch v-model="sidebarMini"></base-switch>
              <div class="small ml-2 mb-2 text-center text-body">SIDEBAR<br/>MINI</div>
            </div>
          </li>
          <div class="dropdown-divider"></div>
          <li class="nav-link">
            <a class="nav-item dropdown-item text-capitalize" v-on:click="saveThemePreferences">
              {{ $t('common.save_preferences') }}
            </a>
          </li>
          <li class="nav-link">
            <a class="nav-item dropdown-item text-capitalize" v-on:click="$router.push({name: 'users.profile'})">
              {{ $t('common.profile') }}
            </a>
          </li>
          <div class="dropdown-divider"></div>
          <li class="nav-link">
            <div class="nav-item dropdown-item" v-on:click="onLogout">Log out</div>
          </li>
        </base-dropdown>
        <li class="nav-item" v-if="this.helper">
          <base-button
            link
            @click="showHelperDrawer = true"
            block>
            <octo-icon icon="study" class="helper-icon" />
          </base-button>
        </li>
      </ul>
    </base-nav>
    <el-drawer
      title="Helper"
      :visible.sync="showHelperDrawer">
      <div class="px-3">
        <component :is="this.helper"/>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {CollapseTransition} from 'vue2-transitions';
import {BaseNav} from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import {mapActions, mapGetters, mapMutations} from "vuex";
import Badge from "@/components/Badge";
import BaseSwitch from "@/components/BaseSwitch";
import {endpoints} from "@/routes/endpoints";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Drawer} from "element-ui";

export default {
  components: {
    OctoIcon,
    BaseSwitch,
    Badge,
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    [Drawer.name]: Drawer,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      themeMode: 'auth/themeMode',
      themeColor: 'auth/themeColor',
      sidebarStatus: 'auth/sidebarStatus',
      helper: 'common/helper',
    }),
    routeName() {
      return this.$route.meta.title;
    },
    profileName() {
      return this.currentUser.username;
    },
    userThemeColors() {
      return this.$_.map(['primary', 'vue', 'blue', 'green', 'red', 'orange'], (color) => {
        return {
          color: color,
          padding: this.themeColor === color ? 'p-3' : 'p-2'
        };

      })
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      imgUser: require('@/assets/images/avatar.svg'),
      themeLight: true,
      sidebarMini: false,
      showHelperDrawer: false
    };
  },
  watch: {
    themeMode: function (val) {
      this.themeLight = val === 'light';
    },
    sidebarStatus: function (val) {
      this.sidebarMini = this.sidebarStatus === 'mini';
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),

    ...mapMutations({
      setThemeColor: 'auth/setThemeColor',
      setThemeMode: 'auth/setThemeMode',
      setCurrentUser: 'auth/setCurrentUser',
      setSidebarStatus: 'auth/setSidebarStatus'
    }),

    toggleSidebar() {
      this.setSidebarStatus(this.sidebarMini ? 'mini' : 'extended');
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },

    toggleMode: function () {
      this.setThemeMode(this.themeLight ? 'light' : 'dark')
      const docClasses = document.body.classList;
      if (this.themeMode === 'light') {
        docClasses.add('white-content');
      } else {
        docClasses.remove('white-content');
      }
    },

    onLogout: function () {
      this.logout()
        .then((resp) => {
          this.$router.push({name: 'auth.login'})
        })
        .catch((err) => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })

    },

    changeThemeColor(color) {
      this.$_.each(this.userThemeColors, item => {
        item.padding = item.color === color ? 'p-3' : 'p-2';
      });

      this.setThemeColor(color)
    },

    saveThemePreferences() {
      this.$api.post(endpoints.USER_UPDATE_THEME_PREFERENCES, {
        theme_color: this.themeColor,
        theme_mode: this.themeMode,
        sidebar_status: this.sidebarStatus
      }).then((data) => {
        this.setCurrentUser(data.data.data);
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      }).catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      })
    }
  }
};
</script>
<style scoped lang="scss">
.top-navbar {
  top: 0;
}
.helper-icon {
  font-size: 2em;
}
</style>
