import Vue from "vue";

export const mutations = {

  setRoles(state, data) {
    state.roles = data;
  },

  setCurrentUser(state, data) {
    state.currentUser = data;
  },

  setAccessToken(state, data) {
    state.accessToken = data;
  },

  setThemeMode(state, data) {
    Vue.set(state.currentUser, 'theme_mode', data);
  },

  setThemeColor(state, data) {
    Vue.set(state.currentUser, 'theme_color', data);
  },

  setSidebarStatus(state, data) {
    Vue.set(state.currentUser, 'sidebar_status', data);
  },

  resetState(state) {
    state.roles = [];
    state.currentUser.id = null;
    state.themeMode = 'light';
    state.themeColor = 'vue';
    state.sidebarStatus = 'extended';
    state.accessToken = null;
  }
}
