<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button/>
    <side-bar
      :background-color="themeColor"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
      :sidebarLinks="menuItems"
      :logo="logoSidebar"
    >
    </side-bar>
    <div class="main-panel" :data="themeColor">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>
      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >

          <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import {SlideYDownTransition, ZoomCenterTransition} from 'vue2-transitions';
import menuItems from "@/routes/menuItems";
import {mapGetters, mapMutations} from "vuex";

export default {
  components: {
    DashboardNavbar,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition
  },
  data() {
    return {
      menuItems: menuItems,
      logoSidebar: require('@/assets/images/logo_sidebar.png'),
    };
  },
  methods: {

    ...mapMutations({
      setBroadcastEvent: 'common/setBroadcastEvent',
    }),

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');
        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {

    this.initScrollbar();
    const docClasses = document.body.classList;
    if (this.themeMode === 'light') {
      docClasses.add('white-content');
    } else {
      docClasses.remove('white-content');
    }

    if (this.sidebarStatus === 'extended') {
      docClasses.remove('sidebar-mini');
    } else {
      docClasses.add('sidebar-mini');
    }

    try {

      this.$echo.leave(`presence-general.${this.currentUser.id}`);

      this.$echo
        .join(`presence-general.${this.currentUser.id}`)
        .here((users) => {
        })
        .joining((user) => {
        })
        .leaving((user) => {
        })
        .listen('.room.presence-general', (e) => {
          this.setBroadcastEvent(e);
        })
        .error((error) => {
          console.error('presence-general', error)
        });

      this.$echo.leave(`private-general.${this.currentUser.id}`);

      this.$echo
        .private(`private-general.${this.currentUser.id}`)
        .listen('.private-general', (e) => {
          this.setBroadcastEvent(e);
        })
      .error((error) => {
        console.error('private-general', error)
      });

      this.$echo.leave(`public-general`);

      this.$echo
        .channel(`public-general`)
        .listen('.public-general', (e) => {
          this.setBroadcastEvent(e);
        })
        .error((error) => {
          console.error('public-general', error)
        });

    } catch (e) {
      console.log('Join Channels error', e);
    }
  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
      themeMode: 'auth/themeMode',
      sidebarStatus: 'auth/sidebarStatus',
      currentUser: 'auth/currentUser',
    })
  },
  destroyed() {
    this.$echo.leave(`presence-general.${this.currentUser.id}`);
    this.$echo.leave(`private-general.${this.currentUser.id}`);
    this.$echo.leave(`public-general`);
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
