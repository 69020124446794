import axios from 'axios';
import store from "@/store";
import router from "@/routes/router";

const VueAxios = {
  install(Vue) {

    if (!Vue.prototype.$api) {
      const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {'spa-version': process.env.VUE_APP_VERSION}
      });

      axiosInstance.interceptors.request.use(
        async(config) => {
          const token = store.state.auth.accessToken
          if (token) {
            config.headers.authorization = 'Bearer ' + token
          }
          return config
        },
        async(err) => {
          throw err
        }
      )

      axiosInstance.interceptors.response.use(
        async (config) => {
          return config
        },
        async (err) =>  {
          const {config, request: {status}} = err;

          if (config) {
            switch (status) {
              case 401:
                await store.dispatch('auth/resetAllStates');
                await router.push({name: 'auth.login'})
                break;
              case 426:
                Vue.prototype.$notify({type: 'warning', message: "Hai una versione obsoleta dell'applicativo, aggiorna premendo F5 o CTRL+SHIFT+R"});
                await store.dispatch('auth/resetAllStates');
                await router.push({name: 'auth.login'});
                break;
              default:
            }
          }

          throw err;

        })

      Object.defineProperty(Vue.prototype, '$api', {value: axiosInstance});
    }
  },
};

export default VueAxios;
