import {permissionService} from "@/util/permission-service";

const SettingsMenuItems =
  {
    roles: [
      permissionService.CALL_CENTER_MANAGER_ROLE,
      permissionService.TEACHING_MANAGER_ROLE
    ],
    link: {
      name: 'sidebar.settings',
      icon: 'settings',
    },
    children: [
      {
        roles: [
          permissionService.CALL_CENTER_MANAGER_ROLE
        ],
        link: {
          name: 'sidebar.call_center',
          route: 'settings.call_center'
        }
      },
      {
        roles: [
          permissionService.SALES_MANAGER_ROLE
        ],
        link: {
          name: 'sidebar.sales',
          route: 'settings.sales'
        }
      },
      {
        roles: [
          permissionService.ADMIN_ROLE
        ],
        link: {
          name: 'sidebar.providers',
          route: 'registries.providers'
        }
      },
      {
        roles: [
          permissionService.TEACHING_MANAGER_ROLE
        ],
        link: {
          name: 'sidebar.didactics',
          route: 'settings.didactics'
        }
      },
      {
        roles: [
          permissionService.CALL_CENTER_MANAGER_ROLE
        ],
        link: {
          name: 'sidebar.email_templates',
          route: 'settings.email_templates'
        }
      },
      {
        roles: [
          permissionService.ADMIN_ROLE
        ],
        link: {
          name: 'sidebar.zoho',
          route: 'settings.zoho'
        }
      }
    ]
  };

export default SettingsMenuItems;
