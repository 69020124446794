import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store'
import {permissionService} from "@/util/permission-service";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach(async (to, from, next) => {

  if (to.meta.requiresAuth && !permissionService.isAllowed(to.meta.roles)) {
    await store.dispatch('auth/resetAllStates');
    next({name: 'auth.login'});
  }

  next();

});

export default router;
