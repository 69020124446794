<template>
  <div class="sidebar" :data="themeColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <img :src="logo" alt="app-logo" class="img-fluid bg-white p-1" />
        <div  class="simple-text logo-normal ml-2">
          {{ title }}
        </div>
      </div>
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(item, index) in sidebarLinks"
            :key="item.link.name + index"
            :link="item.link"
            v-if="permissionService.isAllowed(item.roles)"
          >
            <sidebar-item
              v-for="(subItem, index) in item.children"
              :key="subItem.link.name + index"
              :link="subItem.link"
              v-if="permissionService.isAllowed(subItem.roles)"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import {permissionService} from "@/util/permission-service";
import {mapGetters} from "vuex";

export default {
  name: 'sidebar',
  data() {
    return {
      permissionService: permissionService
    };
  },
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: 'img/icon-vue.png',
      description: 'Sidebar app logo'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {

    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
    }),
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
