import AuthLayout from '@/pages/Layout/AuthLayout.vue';
import UserRoutes from "@/routes/partials/user-routes";
import RegistryRoutes from "@/routes/partials/registries-routes";
import LeadRoutes from "@/routes/partials/leads-routes";
import CallCenterRoutes from "@/routes/partials/call-center-routes";
import DealRoutes from "@/routes/partials/deals-routes";
import CourseRoutes from "@/routes/partials/courses-routes";
import SettingRoutes from "@/routes/partials/settings-routes";
import ReportsRoutes from "@/routes/partials/reports-routes";
import StudentRoutes from "@/routes/partials/students-routes";
import DidacticsRoutes from "./partials/didactics-routes";
import BalancesRoutes from "@/routes/partials/balances-routes";
import EmailRoutes from "@/routes/partials/email-routes";
import PostSellingRoutes from "@/routes/partials/post-selling-routes";

const routes = [
  {
    path: '/',
    component: AuthLayout,
    name: 'auth',
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'auth.login',
        components: { default: () => import('@/pages/Login/LoginPage') },
        meta: {
          requiresAuth: false
        },
      }
    ]
  },
  UserRoutes,
  RegistryRoutes,
  LeadRoutes,
  CallCenterRoutes,
  DealRoutes,
  PostSellingRoutes,
  CourseRoutes,
  SettingRoutes,
  ReportsRoutes,
  StudentRoutes,
  DidacticsRoutes,
  BalancesRoutes,
  EmailRoutes
];

export default routes;
