import {permissionService} from "@/util/permission-service";


const LeadMenuItems = {
  roles: [
    permissionService.CALL_CENTER_MANAGER_ROLE,
    permissionService.CALL_CENTER_OPERATOR_ROLE,
  ],
  link: {
    name: 'sidebar.leads',
    icon: 'filter'
  },
  children: [
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.dashboard',
        route: 'leads.dashboard'
      }
    },
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.new',
        route: 'leads.in_progress.wizard'
      }
    },
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.in_progress',
        route: 'leads.in_progress'
      }
    },
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.list',
        route: 'leads.archive'
      }
    },
    {
      roles: [
        permissionService.CALL_CENTER_MANAGER_ROLE
      ],
      link: {
        name: 'sidebar.campaigns',
        route: 'leads.campaigns'
      }
    },
  ]
};


export default LeadMenuItems;
