import moment from 'moment';

const VueMoment = {
    install(Vue) {
        // prevent duplicate instance on SSR
        if (!Vue.prototype.$moment) {
            Object.defineProperty(Vue.prototype, '$moment', { value: moment });
        }
    },
};

export default VueMoment;
