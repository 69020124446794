import Registry from "@/models/registry";

const Lead = {
  id: null,
  date_in: null,
  date_out: null,
  note: null,
  provider_id: null,
  provider_import_data_id: null,
  registry: _.cloneDeep(Registry),
  registry_id: null,
  status: null,
  sub_status: null
};

export default Lead
