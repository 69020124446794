<template>
  <fade-transition>
    <div
      v-if="visible"
      class="alert"
      :class="[`alert-${alertType}`, { 'alert-with-icon': icon }]"
      role="alert"
    >
      <slot v-if="!dismissible"></slot>
      <template v-else>
        <slot name="dismiss-icon">
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="dismissAlert"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
        </slot>

        <template v-if="icon || $slots.icon">
          <slot name="icon">
            <span data-notify="icon" :class="icon"></span>
          </slot>
        </template>

        <span data-notify="message"> <slot></slot> </span>
      </template>
    </div>
  </fade-transition>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import {mapGetters} from "vuex";

export default {
  name: 'base-alert',
  components: {
    FadeTransition
  },
  props: {
    type: {
      type: String,
      default: null,
      description: 'Alert type'
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: 'Whether alert is dismissible (closeable)'
    },
    icon: {
      type: String,
      default: '',
      description: 'Alert icon to display'
    }
  },
  data() {
    return {
      visible: true
    };
  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
    }),
    alertType: function () {
      if(this.type) {
        return this.type;
      }
      switch(this.themeColor) {
        case 'primary':
          return 'primary';
        case 'vue':
          return 'success';
        case 'green':
          return 'success';
        case 'red':
          return 'danger';
        case 'orange':
          return 'warning';
        case 'blue':
          return 'info';
        default:
          return 'primary';
      }
    }
  },
  methods: {
    dismissAlert() {
      this.visible = false;
    }
  }
};
</script>
