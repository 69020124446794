import {permissionService} from "@/util/permission-service";

const ReportsMenuItems = {
  roles: [
    permissionService.ADMIN_ROLE
  ],
  link: {
    name: 'sidebar.reports',
    icon: 'statistics'
  },
  children: [
    {
      roles: [
        permissionService.ADMIN_ROLE
      ],
      link: {
        name: 'sidebar.new',
        route: 'reports.new.wizard'
      }
    },
    {
      roles: [
        permissionService.ADMIN_ROLE
      ],
      link: {
        name: 'sidebar.reports_list',
        route: 'reports.list'
      }
    }
  ]
};

export default ReportsMenuItems;
