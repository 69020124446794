import Phone from "./phone";
import Email from "./email";

const Registry = {
  id: null,
  code: '',
  family: '',
  nationality: '',
  type: 'private',
  emails: [],
  phones: [],
  addresses: [],
  defaultEmail: Email,
  defaultPhone: Phone,
  defaultAddress: {},
  enabled_certifications: []
};

export default Registry
